import React from "react";

import Button from "../../../../../comps/button";
import Input from "../../../../../comps/input";

import { AiOutlineMessage } from "react-icons/ai";

export default function StepTwo({ readData, writeData, Submit }) {
  return (
    <>
      <Input
        placeholder={"קוד אימות"}
        type={"text"}
        icon={<AiOutlineMessage />}
        value={readData("code").value}
        onChange={(e) => writeData(e.target.value, "code")}
        status={readData("code").status}
        helper_text={readData("code").helper_text}
        label={readData("code").label}
      />
      <Button options={"hover_dark"} onClick={Submit}>
        {"התחברות"}
      </Button>
    </>
  );
}
