import { GET } from "../API";

export default function Flow() {
  const token = localStorage.getItem("token");

  if (!token) return { role: "visitor" };
  return (async function () {
    try {
      const response = await GET("users/me");
      const user = await response.json();

      if (!response.ok) {
        throw new Error();
      }
      return user;
    } catch {
      // todo
      localStorage.removeItem("token");
      // window.location.href = "/";
    }
  })();
}
