import React, { useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdAssignmentReturned } from "react-icons/md";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { GET, POST } from "../../../API";
import Input, { Select } from "../../../comps/input";
import Loading, { LoadingScreenWithHeader } from "../../../comps/loading";
import Notification from "../../../comps/Notification";
import styles from "./style.module.scss";
import Error from "../../../comps/Error";
import Button from "../../../comps/button";
import ProgressBar from "../../../comps/progress_bar";
import GoogleTranslate from "../../../comps/google_translate";

export default function AssignLactors() {
  const GoTo = useNavigate();
  const [searchParams] = useSearchParams();

  const { category } = useParams();

  const [notif, setNotif] = useState();

  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [crrDate, setCrrDate] = useState(searchParams.get("date_id") || "");
  const [crrSubType, setCrrSubType] = useState("");
  const [SubTypeByRequest, setSubTypeByRequest] = useState("");

  const [Dates, setDates] = useState([]);
  const [SubTypes, setSubTypes] = useState([]);

  const [lactors, setLactors] = useState([]);
  const [chosenLactors, setChosenLactors] = useState([]);
  const [LactorsFilter, setLactorsFilter] = useState("");
  const [LoadingLactors, setLoadingLactors] = useState(false);
  const [LactorsError, setLactorsError] = useState(false);
  const [LactorsTotal, setLactorsTotal] = useState(0);

  const [requests, setRequests] = useState([]);
  const [chosenRequests, setChosenRequests] = useState([]);
  const [RequestsFilter, setRequestsFilter] = useState("");
  const [LoadingRequests, setLoadingRequests] = useState(false);
  const [RequestsError, setRequestsError] = useState(false);
  const [RequestsTotal, setRequestsTotal] = useState(0);

  const [loadingSetLactors, setLoadingSetLactors] = useState(false);

  const [RequestFilterId, setRequestFilterId] = useState("");

  //default 3
  const [maxLactors, setMaxLactors] = useState(3);

  useEffect(() => {
    (async function () {
      try {
        const response = await GET(`due-dates/${category}/all`);
        const { dates } = await response.json();

        if (!crrDate) {
          if (dates.length !== 0) setCrrDate(dates[0].id);
        }
        setDates(
          dates.map((date) => ({
            id: date.id,
            name: date.name,
            max_lactors: date.max_lactors,
          }))
        );
        setMaxLactors(
          dates.filter((date) => date.id === crrDate)[0]?.max_lactors || 3
        );
      } catch {
        setPageError(true);
      } finally {
        setPageLoading(false);
      }
    })();
  }, [category]);

  useEffect(() => {
    if (!crrDate) {
      return GoTo(`/${category}/assign-lactors`);
    }
    if (
      !window.location.href.includes(crrDate) &&
      window.location.href.includes("date_id=")
    ) {
      return (window.location.href = `/${category}/assign-lactors?date_id=${crrDate}`);
    }
    GoTo(`/${category}/assign-lactors?date_id=${crrDate}`);

    getLactors();
    getRequests();
    get_subTypes();

    return;
  }, [crrDate]);

  const getLactors = async (loadMore = false) => {
    setLoadingLactors(true);

    try {
      let link = `users/all-lactors/by-date?date_id=${crrDate}&sub_id=${
        crrSubType || ""
      }&search=${LactorsFilter}&amount=20&page=${
        loadMore ? lactors.length / 20 : 0
      }`;
      const response = await GET(link);
      const json = await response.json();

      setLactorsTotal(json.total);

      let newLactors = json.lactors.map((lactor) => ({
        name: `${lactor.first_name} ${lactor.last_name}`,
        id: lactor.id,
        open_reviews: lactor.open_reviews,
        chosen: false,
        done_reviews: lactor.done_reviews,
      }));

      if (loadMore) {
        setLactors(lactors.concat(newLactors));
      } else {
        setLactors(newLactors);
      }
    } catch {
      setLactorsError(true);
    } finally {
      setLoadingLactors(false);
    }
  };

  const format_subTypes = (sub_types) => {
    sub_types.forEach((type, index) => {
      type.name = `${type.name}${" "}(${type.serial_number})`;
    });
    return sub_types.sort((a, b) => a.order - b.order);
  };

  const get_subTypes = async () => {
    try {
      const response = await GET(`art-type/sub-types?date_id=${crrDate}`);
      const { sub_types } = await response.json();
      const formattedTypes = format_subTypes(sub_types);

      setSubTypes([{ id: "", name: "" }, ...formattedTypes]);
    } catch {}
  };

  const getRequests = async (loadMore = false) => {
    if (!crrDate) return;

    setLoadingRequests(true);
    setRequestsError(false);

    let link = `requests/${category}/to-fill?date_id=${crrDate}&sub_id=${
      SubTypeByRequest || ""
    }&req_id=${RequestFilterId}&amount=10&search=${RequestsFilter}&page=${
      loadMore ? requests.length / 10 : 0
    }`;

    try {
      const response = await GET(link);
      const json = await response.json();

      if (loadMore) {
        setRequests(requests.concat(json.requests));
      } else {
        setRequests(json.requests);
      }
      setRequestsTotal(json.total);
    } catch {
      setRequestsError(true);
    } finally {
      setLoadingRequests(false);
    }
  };

  const LactorPicker = (id) => {
    if (chosenLactors.length >= maxLactors) return;

    const theLactor = lactors.find((lactor) => lactor.id === id);

    for (let request of chosenRequests) {
      if (
        chosenLactors.length + 1 + request.lactors_reviews.length >
        maxLactors
      ) {
        sendNotification(
          <>
            <b>{request.project_name}</b> יחרוג ממגבלת הלקטורים של{" "}
            <b>{maxLactors}</b>
          </>
        );
        return;
      }

      if (
        request.lactors_reviews.filter((review) => review.lactor === id).length
      ) {
        sendNotification(
          <>
            <b>{theLactor.name}</b> כבר מוקצה לבקשה{" "}
            <b>{request.project_name}</b>
          </>
        );
        return;
      }
    }

    setLactors(
      lactors.map((lactor) => {
        if (lactor.id === id) {
          return {
            ...lactor,
            chosen: lactor.chosen ? false : chosenLactors.length < maxLactors,
          };
        }
        return lactor;
      })
    );

    setChosenLactors((prev) => [
      ...prev,
      lactors.filter((lactor) => lactor.id === id)[0],
    ]);
  };

  const LactorRemover = (id) => {
    setChosenLactors((prev) => prev.filter((lactor) => lactor.id !== id));
    setLactors(
      lactors.map((lactor) => {
        if (lactor.id === id) {
          return {
            ...lactor,
            chosen: false,
          };
        }
        return lactor;
      })
    );
  };

  const requestsPicker = (id) => {
    const theRequest = requests.filter((request) => request.id === id)[0];

    if (chosenLactors.length + theRequest.lactors_reviews.length > maxLactors) {
      sendNotification(
        <>
          <b>{theRequest.project_name}</b> יחרוג ממגבלת הלקטורים של{" "}
          <b>{maxLactors}</b>
        </>
      );
      return;
    }

    for (let i = 0; i < chosenLactors.length; i++) {
      // find if the lactor is already assigned to the request lactors list
      if (
        theRequest.lactors_reviews.filter(
          (review) => review.lactor === chosenLactors[i].id
        ).length
      ) {
        sendNotification(
          <>
            <b>{chosenLactors[i].name}</b> כבר מוקצה לבקשה{" "}
            <b>{theRequest.project_name}</b>
          </>
        );
        return;
      }
    }

    setRequests(
      requests.map((request) => {
        if (request.id === id) {
          return {
            ...request,
            chosen: !request.chosen,
          };
        }
        return request;
      })
    );
    setChosenRequests((prev) => [
      ...prev,
      requests.filter((request) => request.id === id)[0],
    ]);
  };

  const requestsRemover = (id) => {
    setChosenRequests((prev) => prev.filter((request) => request.id !== id));

    setRequests(
      requests.map((request) => {
        if (request.id === id) {
          return {
            ...request,
            chosen: false,
          };
        }
        return request;
      })
    );
  };

  const HandleSubmit = async () => {
    if (!chosenLactors.length || !chosenRequests.length || notif) return;
    setLoadingSetLactors(true);
    let doc = {
      date_id: crrDate,
      lactors: chosenLactors.map((lactor) => lactor.id),
      requests: chosenRequests.map((request) => request.id),
    };

    const response = await POST(`manage-reviews/add/lactors/${category}`, doc);

    if (!response.ok) {
      setLoadingSetLactors(false);
      return sendNotification("משהו השתבש, נסו שוב");
    }

    // clean up
    setChosenLactors([]);
    setLactors(lactors.map((lactor) => ({ ...lactor, chosen: false })));

    setRequestsTotal(RequestsTotal - chosenRequests.length);
    setChosenRequests([]);
    setRequests(
      requests
        .filter((request) => !doc.requests.includes(request.id))
        .map((request) => ({ ...request, chosen: false }))
    );
    sendNotification("נרשם בהצלחה");
    setLoadingSetLactors(false);
  };

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(""), 3000);
  };

  if (pageError) return <Error />;
  if (pageLoading) return <LoadingScreenWithHeader />;
  return (
    <>
      <GoogleTranslate />
      {notif ? <Notification html={<div>{notif}</div>} /> : null}
      <div className={styles.Container}>
        <div className={styles.Top}>
          {!loadingSetLactors ? (
            chosenLactors.length || chosenRequests.length ? (
              <>
                <p>
                  {chosenLactors.map((lactor, index) => (
                    <b key={index} onClick={() => LactorRemover(lactor.id)}>
                      {lactor.name}
                      {index < chosenLactors.length - 1 ? ", " : ""}
                    </b>
                  ))}
                  {chosenLactors.length && chosenRequests.length ? (
                    <span> {"רשום ל"} </span>
                  ) : null}
                  {chosenRequests.map((request, index) => (
                    <b key={index} onClick={() => requestsRemover(request.id)}>
                      {request.project_name}
                      {index < chosenRequests.length - 1 ? ", " : ""}
                    </b>
                  ))}
                </p>

                {chosenLactors.length && chosenRequests.length ? (
                  <button onClick={HandleSubmit}>
                    <AiOutlineSend />
                  </button>
                ) : null}
              </>
            ) : (
              <span>רשמו לקטורים להגשות</span>
            )
          ) : (
            <Loading />
          )}
        </div>
        <div className={styles.Top}>
          <Select
            options={"small"}
            dropdown={Dates.map((date) => date.name)}
            WhenSubmit={(val) =>
              setCrrDate(Dates.find((date) => date.name === val).id)
            }
            valueIndex={Dates.findIndex((date) => date.id === crrDate)}
          />
        </div>
        <div className={styles.Bottom}>
          <div className={styles.Right}>
            <div className={styles.SearchBar}>
              <Input
                placeholder={`חיפוש לקטורים (${LactorsTotal})`}
                icon={<BiSearch />}
                options={"small"}
                value={LactorsFilter}
                onChange={(e) => setLactorsFilter(e.target.value)}
              />
              <Select
                placeholder={"תת תחום"}
                options={"small"}
                dropdown={SubTypes.map((type) => type.name)}
                WhenSubmit={(val) =>
                  setCrrSubType(SubTypes.find((type) => type.name === val).id)
                }
                valueIndex={SubTypes.findIndex(
                  (type) => type.id === crrSubType
                )}
              />
              <Button options={"x_small"} onClick={() => getLactors()}>
                {" "}
                חיפוש
              </Button>
            </div>
            <div className={styles.Rows}>
              {LoadingLactors ? (
                <Loading />
              ) : LactorsError && !LactorsFilter ? (
                <div>אירעה שגיאה בטעינת הנתונים, נסו שוב</div>
              ) : (
                lactors.map((lactor, index) => (
                  <div
                    key={index}
                    onClick={() => LactorPicker(lactor.id)}
                    className={`${styles.Row} ${
                      lactor.chosen ? styles.Active : ""
                    }`}
                  >
                    <h2>{lactor.name}</h2>

                    <div className={styles.ProgressBarDiv}>
                      <ProgressBar
                        progress={`${
                          (100 * lactor.done_reviews) /
                          (lactor.open_reviews + lactor.done_reviews)
                        }%`}
                      >
                        {lactor.done_reviews + lactor.open_reviews} /{" "}
                        {lactor.done_reviews}
                      </ProgressBar>
                    </div>

                    <div>
                      <MdAssignmentReturned />
                    </div>
                  </div>
                ))
              )}
            </div>
            {!lactors.length && !LoadingLactors ? (
              <div>לא נמצאו לקטורים</div>
            ) : null}
            {LactorsTotal > lactors.length && (
              <button
                className={styles.LoadMore}
                onClick={() => getLactors(true)}
              >
                טען עוד...
              </button>
            )}
          </div>
          <div className={styles.Left}>
            <div className={styles.SearchBar}>
              <Input
                placeholder={`חיפוש הגשה (${RequestsTotal})`}
                icon={<BiSearch />}
                options={"small"}
                value={RequestsFilter}
                onChange={(e) => setRequestsFilter(e.target.value)}
              />
              <Input
                placeholder={`חיפוש מספר הגשה (${RequestsTotal})`}
                icon={<BiSearch />}
                options={"small"}
                type={"number"}
                value={RequestFilterId}
                onChange={(e) => setRequestFilterId(e.target.value)}
              />
              <Select
                placeholder={"תת תחום"}
                options={"small"}
                dropdown={SubTypes.map((type) => type.name)}
                WhenSubmit={(val) =>
                  setSubTypeByRequest(
                    SubTypes.find((type) => type.name === val).id
                  )
                }
                valueIndex={SubTypes.findIndex(
                  (type) => type.id === SubTypeByRequest
                )}
              />
              <Button options={"x_small"} onClick={() => getRequests()}>
                {" "}
                חיפוש
              </Button>
            </div>
            <div className={styles.Cards}>
              {RequestsError ? (
                <div>אירעה שגיאה בטעינת הנתונים, נסו שוב</div>
              ) : requests.length ? (
                requests.map((request, index) => (
                  <div
                    key={index}
                    className={`${styles.Card} ${
                      request.chosen && styles.Active
                    }`}
                    onClick={() => requestsPicker(request.id)}
                  >
                    <div className={styles.Headline}>
                      <h2>{`${request.project_name} (${request.lactors_reviews.length})`}</h2>
                    </div>
                    <div className={styles.Tags}>
                      <div className={styles.Tag}>{request.genre}</div>
                      <div className={styles.Tag}>{request.form_sender}</div>
                      <div className={styles.Tag}>{request.sub_art}</div>
                      <div className={styles.Tag}>{request.request_id}</div>
                    </div>
                  </div>
                ))
              ) : !LoadingRequests ? (
                <span>לא נמצאו הגשות לחיפוש זה</span>
              ) : null}
            </div>
            {LoadingRequests && <Loading />}
            {requests.length < RequestsTotal && (
              <button
                className={styles.LoadMore}
                onClick={() => getRequests(true)}
              >
                טען עוד...
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
