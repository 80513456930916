import React, { useState, useEffect } from "react";
import Button from "../../../../../../comps/button";
import Input from "../../../../../../comps/input";
import styles from "./style.module.scss";

export default function FormStep1({ fields, setFields, submitStep }) {
  const [ErrorsCounter, setErrorsCounter] = useState();

  useEffect(() => {
    setErrorsCounter(
      fields.reduce(
        (prv, crr) => (crr.status === "error" ? (prv += 1) : prv),
        0
      )
    );
  }, [fields]);

  const renderInput = (field) => (
    <Input
      {...field}
      options={"large"}
      onChange={(e) => setFields(field.name, e.target.value)}
    />
  );

  return (
    <div className={styles.Container}>
      {fields.map((field, index) => (
        field.type === "title"? 
          <div key={index}>
            <h3>{field.placeholder}</h3>
          </div>
        :
        <div key={index}>{renderInput(field)}</div>
      ))}
      <div className={styles.Btn}>
        <Button options={"hover_dark"} onClick={submitStep}>
          אישור
        </Button>
        {ErrorsCounter ? (
          <span className={styles.Error}>
            שימו לב, יש לכם {ErrorsCounter} שגיאות
          </span>
        ) : null}
      </div>
    </div>
  );
}
