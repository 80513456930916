import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import styles from "../../style.module.css";

export default function TableFooter({
  ExpectedLength,
  RowsInPage,
  setPaginationVal,
  setRowsInPage,
  PaginationVal,
}) {
  const [SelectLength, setSelectLength] = useState();
  const [PagiCount, setPagiCount] = useState(ExpectedLength || 1);
  const [SelectionValue, setSelectionValue] = useState();

  useEffect(() => {
    if (ExpectedLength < RowsInPage) {
      return setSelectionValue(ExpectedLength);
    }
    setSelectionValue(RowsInPage);
  }, [ExpectedLength, RowsInPage]);

  useEffect(() => {
    let newVal = Math.ceil(ExpectedLength / RowsInPage);

    if (PaginationVal > newVal) {
      // if user click on page4 but theres only 3pages for example
      setPaginationVal(1);
    }
    setPagiCount(newVal || 1);
  }, [RowsInPage, ExpectedLength]);

  useEffect(() => {
    setSelectLength(ExpectedLength > 30 ? 30 : ExpectedLength || 1);
  }, [ExpectedLength]);

  return (
    <div className={styles.TableFooter}>
      <Pagination
        count={PagiCount}
        page={PaginationVal}
        onChange={(_, nv) => setPaginationVal(nv)}
      />
      <select
        value={SelectionValue}
        onChange={(e) => setRowsInPage(e.target.value)}
      >
        {Array(SelectLength)
          .fill()
          .map((_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
      </select>
    </div>
  );
}
