import styles from './style.module.scss';

export default function Loading() {
  return (
    <div className={styles.Loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export function LoadingScreen(){
  return (
    <div className={styles.Container}>
      <Loading />
    </div>
  )
}

export function LoadingScreenWithHeader(){
  return (
    <div className={`${styles.Container} ${styles.LoadingScreenWithHeader}`}>
      <Loading />
    </div>
  )
}