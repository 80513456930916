import React from "react";
import { useParams } from "react-router-dom";
import AllEmails from "./emails";
import EmailById from "./by_id";


export default function AutoMailer() {
  const { action } = useParams();

  switch (action) {
    case "by_id":
      return <EmailById />;
    default:
      return <AllEmails />;
  }
}
