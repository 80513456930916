import React, { useEffect, useState } from "react";
import Button from "../../../../../../comps/button";
import Input, { Select } from "../../../../../../comps/input";
import styles from "./style.module.scss";

export default function FormStep1({ fields, setFields, submitStep }) {
    const [ErrorsCounter, setErrorsCounter] = useState(10);

    useEffect(() => {
        setErrorsCounter(
            fields.reduce(
                (prv, crr) => (crr.status === "error" ? (prv += 1) : prv),
                0
            )
        );
    }, [fields]);

    const renderElement = (field) => {
        if (field.type === "select") {
            return renderSelect(field);
        } else if (field.type === "title") {
            return renderTitle(field);
        } else return renderInput(field);
    };

    const renderTitle = (field) => (
        <div className={styles.titleField}>
            <h3
                style={{
                    color: field.color || "black",
                }}
            >{field.placeholder}</h3>
        </div>
    );

    const renderInput = (field) => (
        <Input
            {...field}
            options={"large"}
            onChange={(e) => setFields(field.name, e.target.value)}
        />
    );

    const renderSelect = (field) => (
        <Select
            {...field}
            options={"large"}
            WhenSubmit={(v) => setFields(field.name, v)}
            valueIndex={field.dropdown.indexOf(field.value) || 0}
        />
    );

    return (
        <div className={styles.Container}>
            {fields.map((field, index) => (
              field.displayInput ? <div key={index}>{renderElement(field)}</div> : null
            ))}
            <div className={styles.Btn}>
                <Button options={"hover_dark"} onClick={submitStep}>
                    אישור
                </Button>
                {ErrorsCounter ? (
                    <span className={styles.Error}>
                        שימו לב, יש לכם {ErrorsCounter} שגיאות
                    </span>
                ) : null}
            </div>
        </div>
    );
}
