import React from "react";
import styles from "./style.module.scss";
import { GrClose } from "react-icons/gr";

export default function FilePreview({ close, onLoad, link }) {
  return (
    <div className={styles.Container}>
      <button onClick={close}>
        <GrClose />
      </button>
      <embed src={link} onLoad={onLoad}></embed>
    </div>
  );
}
