import uuid from 'react-uuid';

export const transformDataToFormField = (key, fieldData) => {
  return {
    id: fieldData.id || uuid(), // Ensure each field has a unique ID
    key: key,
    hebrewLabel: fieldData.hebrew_name || '', // Adjust based on actual property names
    type: fieldData.type,
    required: fieldData.require || false,
    order: fieldData.order,
    range: fieldData.range ? fieldData.range[1] : '',
    dependsOn: fieldData?.dependencies ? fieldData.dependencies[0].field : '', // Add additional transformations as needed
    condition: fieldData?.dependencies ? fieldData.dependencies[0].condition : '', // Add additional transformations as needed
  };
};

export const transformDataToFileFormField = (key, fieldData) => {
  return {
    id: fieldData.id || uuid(), // Ensure each field has a unique ID
    key: key,
    hebrewLabel: fieldData.hebrew_name || '', // Adjust based on actual property names
    required: fieldData.require || false,
    order: fieldData.order,
    max: fieldData.max,
    private: fieldData.private,
    comment: fieldData.comment,
  };
};
