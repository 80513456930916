import React, { useEffect, useState } from "react";
import Button from "../../../../comps/button";
import Input, { Select } from "../../../../comps/input";
import styles from "./style.module.scss";
import { GET, POST } from "../../../../API";
import { default as Config } from "../../../../config";
import { useSearchParams } from "react-router-dom";
import Notification from "../../../../comps/Notification";
import Checkbox from "../../../../comps/checkbox";
import { LoadingScreenWithHeader } from "../../../../comps/loading";

export default function PutUser() {
  const [searchParams] = useSearchParams();

  const user_id = searchParams.get("id") || "";
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [comment, setComment] = useState("");
  const [chosenProArts, setChosenProArts] = useState([]);
  const [chosenSubTypes, setChosenSubTypes] = useState([]);


  const [Role, setRole] = useState("");
  const [Roles, setRoles] = useState([]);
  const [valueIndex, setValueIndex] = useState(0);

  const [ProArts, setProArts] = useState([]);

  const [Notif, setNotif] = useState();

  const [loading, setLoading] = useState(false);

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(), 3000);
  };

  useEffect(() => {
    if (user_id) {
      setLoading(true);
      get_user();
    }
  }, [user_id]);

  useEffect(() => {
    if (Role) {
      setValueIndex(Roles.findIndex((role) => Config[role.role_name] === Role));
    }
  }, [Roles, Role]);

  useEffect(() => {
    getRoles();
    getProArts();
  }, []);

  const get_user = async () => {
    try {
      const response = await GET(`users/by-id?id=${user_id}`);
      const json = await response.json();

      setFirstName(json.first_name);
      setLastName(json.last_name);
      setEmail(json.email);
      setMobile(json.mobile);
      setComment(json.comment);
      setRole(Config[json.role]);
      setChosenProArts(json.pro_arts || []);
      setChosenSubTypes(json.pro_sub_arts || []);

    } catch {
      sendNotification("אירעה שגיאה בטעינת המשתמש");
    } finally {
      setLoading(false);
    }
  };

  const getProArts = async () => {
    try {
      const response = await GET(`art-type/all+subs`);
      const arts  = await response.json();

      setProArts(arts);
    } catch {
      sendNotification("שגיאה בטעינת תחומים");
    }
  };

  const getRoles = async () => {
    try {
      const response = await GET(`get-data/all-roles`);
      const { roles, error } = await response.json();

      if (error) {
        throw new Error();
      }

      setRoles(roles);
    } catch {
      setRoles([{ role_name: "שגיאה בטעינה", id: "" }]);
    }
  };

  const HandleSubmit = async () => {
    let doc = {
      first_name,
      last_name,
      email,
      comment,
      mobile,
      role: Roles[valueIndex].id,
      pro_arts: chosenProArts,
      pro_sub_arts:chosenSubTypes
    };

    let link = "manage-users/";
    if (user_id) {
      link += `edit?id=${user_id}`;
    } else {
      link += "add";
    }

    try {
      const response = await POST(link, doc);

      if (response.ok) {
        sendNotification("הפרטים נשמרו בהצלחה");
        if (!user_id) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobile("");
          setComment("");
          setChosenProArts([]);
          setChosenSubTypes([]);
        };
      } else if(response.status === 406){
        sendNotification("המשתמש קיים במערכת");
      } else if(response.status === 402){
        sendNotification("אנא הכנס מספר פלאפון עם ספרות בלבד");
      } else{
        const json = await response.json();
        if (!json){
          throw new Error();
        }else if (json.error === "email"){
          sendNotification("כתובת מייל לא תקנית");
        }else if (json.error === "mobile"){
          sendNotification("מספר פלאפון לא תקני");
        }else{
          throw new Error();
        };
      }
    } catch {
      sendNotification("אירעה שגיאה בשמירת הפרטים");
    }
  };

  const HandleChooseArt = (index) => {
    if (chosenProArts.includes(ProArts[index].id)) {
      setChosenProArts(chosenProArts.filter((id) => id !== ProArts[index].id));
    } else {
      setChosenProArts([...chosenProArts, ProArts[index].id]);
    }
  };

  const HandleSubTypes = (index, sub_index) => {
    if (chosenSubTypes.includes(ProArts[index].sub_types[sub_index].id)) {
      setChosenSubTypes(chosenSubTypes.filter((id) => id !== ProArts[index].sub_types[sub_index].id));
    } else {
      setChosenSubTypes([...chosenSubTypes, ProArts[index].sub_types[sub_index].id]);
    }
  };

  if (loading) return <LoadingScreenWithHeader />;
  return (
    <div className={styles.Container}>
      {Notif && <Notification html={Notif} />}
      <h1>
        {user_id ? "עריכת " : "הוספת "}
        משתמש
      </h1>
      <div className={styles.Form}>
      <Input
        options={"large"}
        value={first_name ?? ""}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder={"שם פרטי"}
      />
      <Input
        options={"large"}
        value={last_name ?? ""}
        onChange={(e) => setLastName(e.target.value)}
        placeholder={"שם משפחה"}
      />
      <Input
        options={"large"}
        placeholder={"תיבת מייל" ?? ""}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        options={"large"}
        placeholder={"מובייל"}
        value={mobile ?? ""}
        onChange={(e) => setMobile(e.target.value)}
      />
       <Input
        options={"large"}
        placeholder={"הערה"}
        value={comment ?? ""}
        onChange={(e) => setComment(e.target.value)}
      />
      <Select
        options={"large"}
        dropdown={Roles.map((role) => Config[role.role_name])}
        WhenSubmit={(val, _) => setRole(val)}
        valueIndex={valueIndex}
      />
      </div>
      <div className={styles.ProArts}>
      <h3>תחומים לבחירה</h3>
        {ProArts.map((art, index) => (
          <div>
          <Checkbox
            label={art.name}
            key={index}
            checked={chosenProArts.includes(art.id)}
            onChange={() => HandleChooseArt(index)}
          />
            {
            <div className={styles.SubTypes}>
            {chosenProArts.includes(art.id) ?  
               art.sub_types.sort((a, b) => a.order - b.order).map((item, sub_index) => (
                  <Checkbox
                   label={item.name}
                   key={sub_index}
                   checked={chosenSubTypes.includes(item.id)}
                   onChange={() => HandleSubTypes(index,sub_index)}
                 />
               ))
                 : null 
              }
              </div>
            }
          </div>
      ))}
      </div>
      <Button onClick={HandleSubmit} options={"large hover_dark"}>
        שמור
      </Button>
    </div>
  );
}
