import styles from "./style.module.scss";
import Button from "../button";

export default function Error(props) {
  return (
    <div className={styles.Container}>
      <span>{props.code || ""}</span>
      <h1>{props.text ?? "שגיאה"}</h1>
      <p>{props.description ?? "אירעה שגיאה בלתי צפויה, אנא נסו שוב."}</p>
      {props.btn ?? (
        <Button onClick={() => window.location.reload()}>נסו שוב</Button>
      )}
    </div>
  );
}
