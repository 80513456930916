import React from "react";
import { useParams } from "react-router-dom";
import ManageDate from "./manage_date";
import Dates from "./Dates";

export default function DatesManagment() {
  const { action } = useParams();

  switch (action) {
    case "manage_date":
      return <ManageDate />;
    default:
      return <Dates />;
  }
}
