import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GET, DELETE, POST_FORM_DATA } from "../../../API";
import Button from "../../../comps/button";
import Input from "../../../comps/input";
import styles from "./style.module.scss";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineSave } from "react-icons/ai";
import { FaFileUpload } from "react-icons/fa";
import Dialog from "../../../comps/dialog";
import Notification from "../../../comps/Notification";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";

export default function ViewFile() {
  const [searchParams] = useSearchParams();
  const { category } = useParams();

  const main_id = searchParams.get("main_id");
  const file_key = searchParams.get("file_key");

  const [Files, setFiles] = useState([]);
  const [max, setMax] = useState();

  const [UpdateFiles, setUpdateFiles] = useState([]);

  const UpdateFile = (file, index) => {
    if (!Files[index]) {
      let fs = [...Files];
      fs[index] = URL.createObjectURL(file);
      setFiles(fs);
    }

    let newFiles = [...UpdateFiles];
    newFiles[index] = file;
    setUpdateFiles(newFiles);
  };

  const [theDialog, setTheDialog] = useState();
  const [Notif, setNotif] = useState();

  useEffect(() => {
    if (!main_id || !file_key) return;


    get_file();
  }, [main_id, file_key]);

  const get_file = async () => {
    const response = await GET(
      `get-data/${category}/file?request_id=${main_id}&file_type=${file_key}`
    );
    let { files, max } = await response.json();

    setFiles(files);
    setMax(max);
  };

  const sendDialog = (text, onYes) => {
    setTheDialog({
      text,
      onYes,
      onNo: () => setTheDialog(),
    });
  };

  const HandleWantToSave = (index) => {
    sendDialog("לשמור את הקובץ החדש?", () => editFile(index));
  };
  const HandleWantToDelete = (index) => {
    sendDialog("האם אתה בטוח שברצונך למחוק?", () => deleteFile(index));
  };

  const deleteFile = async (index) => {
    setTheDialog();

    try {
      const response = await DELETE(
        `manage-requests/${category}/files?id=${main_id}&file_name=${file_key}&index=${index}`
      );

      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error();
      }
    } catch {
      sendNotification("אירעה שגיאה במחיקת הקובץ");
    }
  };

  const editFile = async (index) => {
    setTheDialog();

    let formData = new FormData();
    formData.append("file", UpdateFiles[index]);

    const response = await POST_FORM_DATA(
      `manage-requests/${category}/files?id=${main_id}&file_name=${file_key}&index=${index}`,
      formData
    );

    if (response.ok) {
      sendNotification("נערך בהצלחה");

      let hostedFile = URL.createObjectURL(UpdateFiles[index]);
      let fs = [...Files];
      fs[index] = hostedFile;
      setFiles(fs);
    }
  };

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(""), 3000);
  };

  return (
    <div className={styles.Container}>
      {theDialog && <Dialog {...theDialog} />}
      {Notif && <Notification html={Notif} />}
      <h1>ניהול קבצים</h1>
      {Files.map((file, index) => (
        <div key={index} className={styles.screen}>
          {file && (
            <div className={styles.File}>
              <embed src={file} type={"application/pdf"} />
            </div>
          )}
          <div className={styles.Changes}>
            <Input
              type={"file"}
              placeholder={UpdateFiles[index]?.name || "העלאת קובץ חדש"}
              icon={<FaFileUpload />}
              onChange={(e) => UpdateFile(e.target.files[0], index)}
            />
            <div key={1}>
              <Button
                onClick={() => HandleWantToSave(index)}
                options={"square hover_dark"}
              >
                <AiOutlineSave />
              </Button>
              <Button
                onClick={() => HandleWantToDelete(index)}
                options={"square hover_dark error"}
              >
                <AiFillDelete />
              </Button>
            </div>
          </div>
        </div>
      ))}
      {Files.length < max ? (
        <div className={styles.Add}>
          <Button onClick={() => setFiles([...Files, ""])}>
            הוסף קובץ חדש <AiOutlinePlusCircle />
          </Button>
        </div>
      ) : null}
    </div>
  );
}
