import LactorOpenDates from "./dates";
import Reviews from "./reviews";
import { useParams } from "react-router-dom";
import LactorForm from "./form";
import Error from "../../../comps/Error";
import Button from "../../../comps/button";

export default function LactorRequests() {
  switch (useParams().action) {
    case "all-dates":
      return <LactorOpenDates />;
    case "reviews":
      return <Reviews />;
    case "form":
      return <LactorForm />;
    default:
      return (
        <Error
          text={"404"}
          description={"העמוד אינו קיים"}
          btn={
            <Button onClick={() => (window.location.href = "/")}>
              לדף הבית
            </Button>
          }
        />
      );
  }
}
