import React, { useEffect, useRef, useState } from "react";
import { BiWindowOpen } from "react-icons/bi";
import { GET, POST } from "../../../../API";
import Button from "../../../../comps/button";
import Input from "../../../../comps/input";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import Notification from "../../../../comps/Notification";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Error from "../../../../comps/Error";

const Errors = {
  400: "מה אתה מנסה לעשות?",
  401: "שדה זה הינו חובה",
  404: "הפעולה שנדרשה לא נמצאה",
  410: "ההגשה הזו אינה בתוקף",
};

export default function LactorForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { category } = useParams();

  const [Title, setTitle] = useState("");
  const [finalReviewText, setFinalReviewText] = useState("");

  const review_id = useRef("");
  const [Form, setForm] = useState([]);
  const [requestID, setRequestID] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState();

  const [GradeTable, setGradeTable] = useState([]);
  const [MainReview, setMainReview] = useState({ text: "" });

  const [Notif, setNotif] = useState("");

  useEffect(() => {
    if (!id) return;
    get_form();
  }, [id]);

  const get_form = async () => {
    setIsLoading(true);
    try {
      const response = await GET(`requests/${category}/by-id?id=${id}`);

      const json = await response.json();

      setFinalReviewText(json.finall_review_text);
      setRequestID(json.request_id);
      setTitle(json.review.script_name);
      setForm(formatForm(json));
      setGradeTable(json.review.grade_table);
      review_id.current = json.review.id;
      setMainReview({ text: json.review.finall_review || "" });
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formatForm = (form) => {
    let textFields = [];
    for (const [key, value] of Object.entries(form.text)) {
      textFields.push({
        name: key,
        type: "text",
        disabled: true,
        label: value.hebrew_name,
        placeholder: value.hebrew_name,
        ...value,
      });
    }
    textFields.sort((a, b) => a.order - b.order);

    let files = [];
    for (const [key, value] of Object.entries(form.files)) {
      files.push({
        name: key,
        type: "file",
        placeholder: formatFilePlaceholder(value.links.length),
        label: value.hebrew_name,
        disabled: true,
        ...value,
        type: "file",
      });
    }
    files.sort((a, b) => a.order - b.order);

    return [...textFields, { type: "url", ...form.link }, ...files];
  };

  const formatFilePlaceholder = (amount) => {
    switch (amount) {
      case 0:
        return `אין קבצים`;
      case 1:
        return `קובץ אחד בשדה זה`;
      default:
        return `${amount} קבצים בשדה זה`;
    }
  };

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(""), 3000);
  };

  const HandleChangeTable = (value, index, key) => {
    let newTable = [...GradeTable];
    newTable[index][key] = value;

    newTable[index].reasoning_helper_text = "";
    newTable[index].reasoning_status = "";
    newTable[index].score_status = "";

    setGradeTable(newTable);
  };

  const HandleShowFile = (index) => {
    let newForm = [...Form];
    newForm[index].show = !newForm[index].show;
    setForm(newForm);
  };

  const HandleNextReview = async () => {
    setIsLoading(true);
    try {
      const response = await GET(
        `get-reviews/${category}/next?crr_request_id=${id}`
      );

      const json = await response.json();

      if (json.review) {
        // change the param id to the next review id
        setSearchParams({ id: json.review.the_request });
      } else {
        sendNotification("סיימת לתת ביקורות לכל ההגשות במועד זה");
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const convertLink = (link) => {
    try {
      if (link.includes("youtube.com") || link.includes("youtu.be")) {
        let id = link.split("v=")[1];

        if (id) {
          return `https://www.youtube.com/embed/${id}`;
        } else return link;
      }

      if (link.includes("vimeo.com")) {
        return link
          .replace("vimeo", "player.vimeo")
          .replace(".com/", ".com/video/");
      }
      return null;
    } catch {
      return null;
    }
  };
  const HandleSubmit = async (done) => {
    let doc = {
      finall_review: MainReview.text,
      grade_table: GradeTable,
      done,
      review_id: review_id.current,
    };

    try {
      const response = await POST(`write-review/${category}`, doc);
      let { error } = await response.json();

      if (response.status === 201) {
        if (done) {
          return sendNotification("הוגשה בהצלחה");
        }
        return sendNotification("נשמר בהצלחה");
      }
      if (error === "finall_review") {
        setMainReview({
          ...MainReview,
          status: "error",
          helper_text: Errors[response.status],
        });
        return sendNotification("שגיאה באחת התיבות");
      }
      error = error.split(":");
      let newGT = [...GradeTable];

      newGT[error[1]][error[0] + "_status"] = "error";
      if (error[0] === "reasoning") {
        newGT[error[1]][error[0] + "_helper_text"] = Errors[response.status];
      }
      setGradeTable(newGT);
      return sendNotification("שגיאה באחת התיבות");
    } catch {
      sendNotification("אירעה שגיאה בלתי צפויה");
    }
  };

  if (isLoading) return <LoadingScreenWithHeader />;
  if (isError) return <Error />;
  return (
    <div className={styles.Container}>
      {Notif && <Notification html={Notif} />}
      <h1>{Title}</h1>
      <h4>מספר בקשה: {requestID} </h4>
      <div className={styles.Fields}>
        {Form.map((item, index) => {
          if (item.type === "url") {
            if (item.password) {
              return (
                <div key={index}>
                  <div className={styles.BlockedIframe}>
                    <h3>אמור להיות כאן סרטון, אבל יש לו סיסמה :(</h3>
                    <span>{item.password || ""}</span>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      {item.url}
                    </a>
                  </div>
                </div>
              );
            } else if (!convertLink(item.url) && item.url) {
              return (
                <div key={index}>
                  <div className={styles.BlockedIframe}>
                    <h3>קשה לי לטעון את הלינק, משהו כנראה שבור</h3>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      {item.url}
                    </a>
                  </div>
                </div>
              );
            } else if (convertLink(item.url)) {
              return (
                <div key={index} className={styles.Embed}>
                  <a href={item.url} target="blank">
                    {item.url}
                  </a>
                  <iframe
                    title={convertLink(item.url)}
                    src={convertLink(item.url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              );
            } else {
              return <></>;
            }
          }
          if (item.type === "file") {
            return (
              <div key={index}>
                <Input
                  label={item.label}
                  placeholder={item.placeholder}
                  icon={
                    <div
                      className={`${styles.file} ${
                        item.show ? styles.show : ""
                      }`}
                      onClick={() => HandleShowFile(index)}
                    >
                      <BiWindowOpen />
                    </div>
                  }
                />
                {item.show && (
                  <div className={styles.Embed}>
                    {item.links.map((link, i) => (
                      <iframe
                        title={link}
                        className={styles.PDF}
                        key={i}
                        src={link}
                        type={"application/pdf"}
                      ></iframe>
                    ))}
                  </div>
                )}
              </div>
            );
          }
          return (
            <div key={index}>
              <Input {...item} />
            </div>
          );
        })}
      </div>
      <h2>ביקורת</h2>
      {GradeTable.map((item, index) => (
        <div className={styles.Grade} key={index}>
          <span>{item.grade}</span>
          <Input
            type={"textarea"}
            placeholder={"ביקורת"}
            value={item.reasoning || ""}
            label={item.reasoning ? "ביקורת" : ""}
            onChange={(e) =>
              HandleChangeTable(e.target.value, index, "reasoning")
            }
            status={item.reasoning_status}
            helper_text={item.reasoning_helper_text}
          />
          <label>
            ציון ({item.score || 0})
            <input
              type="range"
              min="0"
              max="10"
              value={item.score || "0"}
              onChange={(e) =>
                HandleChangeTable(e.target.value, index, "score")
              }
            />
            {item.score_status && (
              <small style={{ color: "red" }}>דירוג חייב להיות גדול מ0</small>
            )}
          </label>
        </div>
      ))}
      <div className={styles.Grade}>
        <h3>{finalReviewText}</h3>
        <Input
          options={"large"}
          placeholder={"ביקורת"}
          type={"textarea"}
          value={MainReview.text || ""}
          onChange={(e) => setMainReview({ text: e.target.value })}
          status={MainReview.status}
          helper_text={MainReview.helper_text}
        />
      </div>
      <Button options={"hover_dark small"} onClick={() => HandleSubmit(false)}>
        שמור לבינתיים
      </Button>
      <Button options={"hover_dark small"} onClick={() => HandleSubmit(true)}>
        אישור ושמירה
      </Button>
      <Button options={"hover_dark small"} onClick={HandleNextReview}>
        חוות דעת הבאה
      </Button>
    </div>
  );
}
