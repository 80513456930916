import { useEffect, useState } from "react";
import styles from "./style.module.scss";

import { AiOutlineArrowDown } from "react-icons/ai";

const renderHelperText = (helper) => {
  if (Array.isArray(helper)) {
    return helper.map((txt, index) => (
      <div className={styles.Helper} key={index}>
        {txt}
      </div>
    ));
  }
  return <div className={styles.Helper}>{helper}</div>;
};

export default function Input(props) {
  const HandleClickInput = (e) => {
    try {
      e.target.parentElement.previousSibling.click();
    } catch {}
  };

  return (
    <div className={`${styles.InpContainer} ${styles[props.status]}`}>
      <label>{props.label}</label>
      {props.comment ? (
        <label className={styles.comment}>{props.comment}</label>
      ) : null}
      <div
        className={`${styles.inp} ${styles[props.type]}  ${
          props.type === "file" ? styles.file : ""
        } ${
          props.options?.length &&
          props.options
            .split(" ")
            .map((opt) => styles[opt])
            .join(" ")
        }`}
      >
        {/* shows the lil translation */}
        {false && props.canTranslate && props.translate ? (
          <div className={styles.translatePlaceholder}>
            <span>{props.value}</span>
          </div>
        ) : null}

        {props.type === "file" && (
          <label
            className={styles.FilesLabel}
            onClick={(e) => e.target.nextElementSibling.click()}
          >
            {props.placeholder || "העלאת קובץ"}
          </label>
        )}
        {props.type === "textarea" ? (
          <textarea {...props}>{props.value}</textarea>
        ) : (
          <input {...props} />
        )}
        {props.icon && (
          <div className={styles.Icon} onClick={HandleClickInput}>
            {props.icon}
          </div>
        )}
      </div>

      {renderHelperText(props.helper_text)}
    </div>
  );
}

export function setTextAreaHeight() {
  var textareas = document.getElementsByTagName("textarea")
  for (var i = 0; i < textareas.length; i++) {
    textareas[i].style.height = textareas[i].scrollHeight + 'px'
  }
}

export function Select({ dropdown, WhenSubmit, valueIndex, ...props }) {
  const [selected, setSelected] = useState(0);
  const [HiddenMenu, setHiddenMenu] = useState(true);

  useEffect(() => {
    setSelected(valueIndex > 0 ? valueIndex : 0);
  }, [valueIndex]);

  const HandlePick = (index) => {
    setHiddenMenu(true);
    setSelected(index);

    try {
      WhenSubmit(dropdown[index], dropdown.indexOf(dropdown[index]));
    } catch {
      throw Error("WhenSubmit or dropdown are not defined");
    }
  };

  return (
    <div className={`${styles.InpContainer} ${styles.Select}`}>
      <Input
        {...props}
        value={dropdown[selected] || ""}
        onChange={() => {}}
        onFocus={() => setHiddenMenu(false)}
        icon={
          <div
            style={{ display: "grid", placeItems: "center", cursor: "pointer" }}
            onClick={() => setHiddenMenu(!HiddenMenu)}
          >
            <AiOutlineArrowDown />
          </div>
        }
      />

      <div className={styles.DropDown} hidden={HiddenMenu}>
        {dropdown.map((opt, index) => (
          <div
            key={index}
            className={selected === index ? styles.Active : ""}
            onClick={() => HandlePick(index)}
          >
            {opt}
          </div>
        ))}
      </div>
    </div>
  );
}
