import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "../pages/auth/sign-in";
import VisitorController from "../pages/visitor";
import AdminController from "../pages/admin";
import FormBuilderContainer from "../pages/admin/formBuilder/";
import MainPage from "../pages/admin/formBuilder/mainPage";

import ArtManagerController from "../pages/artManager";

import ViewFile from "../pages/admin/view_file";
import LactorsController from "../pages/lactor";

import Logout from "../pages/auth/logout";
import Error404 from "../pages/auth/404";


export function Visitor() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<VisitorController />} />
        <Route path="/:category" element={<VisitorController />} />
        <Route path="/:category/:route/:action" element={<VisitorController />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/form-builder" element={<FormBuilderContainer />} />
        <Route path="/form-builder/:formId" element={<FormBuilderContainer />} />
        <Route path="/:category/form-builder/:formId" element={<FormBuilderContainer />} />
        <Route path="/forms" element={<MainPage />} />
      </Routes>
    </Router>
  );
};

export function Admin() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AdminController />} />
        <Route path="/:category/view_file" element={<ViewFile />} />
        <Route path="/:category" element={<AdminController />} />
        <Route path="/:category/:route" element={<AdminController />} />
        <Route path="/:category/:route/:action" element={<AdminController />} />
        <Route path="/logout/:token" element={<Logout />} />
      </Routes>
    </Router>
  );
};

export function Lactor() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LactorsController />} />
        <Route path="/:category" element={<LactorsController />} />
        <Route path="/:category/:route" element={<LactorsController />} />
        <Route
          path="/:category/:route/:action"
          element={<LactorsController />}
        />
        <Route path="/logout/:token" element={<Logout />} />
      </Routes>
    </Router>
  );
};

export function ArtManager() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ArtManagerController />} />
        <Route path="/:category" element={<ArtManagerController />} />
        <Route path="/:category/view_file" element={<ViewFile />} />
        <Route path="/:category/:route" element={<ArtManagerController />} />
        <Route
          path="/:category/:route/:action"
          element={<ArtManagerController />}
        />
        <Route path="/logout/:token" element={<Logout />} />
      </Routes>
    </Router>
  );
};