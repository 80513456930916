import Header from "../../comps/header";
import React, { useEffect } from "react";
import OpenDates from "./open_dates";
import { useParams } from "react-router-dom";
import ArtPicker, { CATEGORIES } from "../../comps/ArtPicker";

let links = [

];

export default function VisitorController() {

  const { category } = useParams();

  useEffect(() => {

    if(category){
      setColor(category);
    };

  }, [category]);


  const setColor = (cat) => {
    CATEGORIES.forEach(item => {
      if (item.type === cat) {
        document.querySelector(":root").style.setProperty("--primary", item.color);
      };
    });
  };

  if (!CATEGORIES.some(cat => cat.type === category)) return <ArtPicker />;
  
  return (
    <>
      <Header links={links}/>
      <OpenDates />
    </>
  );
}
