import React, { useState, useEffect } from "react";
import Button from "../../../../comps/button";
import Input from "../../../../comps/input";
import { Select } from "../../../../comps/input";
import styles from "./style.module.scss";
import Notification from "../../../../comps/Notification";
import { POST, GET } from "../../../../API";
import Loading from "../../../../comps/loading";
import { useSearchParams, useParams } from "react-router-dom";
import { default as Config } from "../../../../config";
import Checkbox from "../../../../comps/checkbox";

export default function ManageDate() {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const date_id = searchParams.get("date_id") || "";
    const [date_name, setDateName] = useState();

    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [art_type, setArtType] = useState("");
    const [subTypes, setSubTypes] = useState([]);
    const [excludeSubTypes, setExcludeSubTypes] = useState([]);

    const [fetchLoading, setLoading] = useState(false);
    const [Notif, setNotif] = useState();

    const [artsTypes, setArts] = useState([]);

    const formatDate = (date) => new Date(date).toISOString().split("T")[0];

    useEffect(() => {
        if (date_id) {
            (async function () {
                try {
                    const response = await GET(
                        `due-dates/by-id?date_id=${date_id}`
                    );
                    const {
                        name,
                        art_type,
                        start_date,
                        end_date,
                        exclude_sub_types,
                    } = await response.json();
                    setDateName(name);
                    setArtType(art_type);
                    setStart(formatDate(start_date));
                    setEnd(formatDate(end_date));
                    setExcludeSubTypes(exclude_sub_types || []);
                } catch {
                    throw new Error();
                } finally {
                }
            })();
        }
    }, [date_id]);

    useEffect(() => {
        (async function () {
            setLoading(true);
            try {
                const response = await GET(`art-type/${category}/all-arts`);
                const { arts } = await response.json();

                if (!date_id) {
                    setArtType(arts[0].id);
                }
                // for file we need to give the option to exclude sub types
                // for the date the user is opening / editing
                if (category === "film") {
                    const res = await GET(`art-type/sub?en_name=movie`);

                    const { sub_types } = await res.json();
                    setSubTypes(sub_types || []);
                }

                if (!arts) throw new Error();
                setArts(arts.map((art) => ({ id: art.id, name: art.name })));
            } catch {
                throw new Error();
            } finally {
                setLoading(false);
            }
        })();
    }, [category]);

    const HandleSubmit = async () => {
        if (!date_name || !start || !end)
            return sendNotification("חובה למלא את כל השדות");

        setLoading(true);

        let doc = {
            art_id: art_type,
            date_name,
            start: new Date(start).getTime() / 1000 || 1,
            end: new Date(end).getTime() / 1000,
            exclude_sub_types: excludeSubTypes,
        };

        try {
            let response;
            if (date_id) {
                response = await POST(`due-dates/edit?date_id=${date_id}`, doc);
            } else {
                response = await POST("due-dates/add", doc);
            }

            if (response.status !== 200) {
                throw new Error();
            } else {
                if (date_id) {
                    sendNotification("נערך בהצלחה");
                } else {
                    sendNotification("התאריך נוסף בהצלחה");
                }
            }
        } catch {
            sendNotification("התרחשה שגיאה בשרת");
        } finally {
            setLoading(false);
        }
    };

    const sendNotification = (text) => {
        setNotif(text);
        setTimeout(() => setNotif(""), 3000);
    };

    const handleExcludeSubTypes = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            setExcludeSubTypes((pre) => [...pre, value]);
        } else {
            setExcludeSubTypes((pre) => pre.filter((sub) => sub !== value));
        }
    };

    return (
        <>
            {fetchLoading ? (
                <div className={styles.Container}>
                    <div className={styles.RotateLoading}>
                        {" "}
                        <Loading />{" "}
                    </div>
                </div>
            ) : (
                <div className={styles.Container}>
                    {Notif && <Notification html={Notif} />}
                    <h1>
                        {date_id ? "עריכת תאריך" : "הוספת תאריך"} -{" "}
                        {Config[category]}
                    </h1>
                    <div>
                        <Input
                            value={date_name}
                            onChange={(e) => setDateName(e.target.value)}
                            options={"large"}
                            placeholder={"שם מועד"}
                            label={<div className={styles.label}>שם מועד</div>}
                        />
                        <Input
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            options={"large"}
                            placeholder={"תאריך פתיחה"}
                            label={
                                <div className={styles.label}>תאריך פתיחה</div>
                            }
                            type={"date"}
                        />
                        <Input
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            label={
                                <div className={styles.label}>תאריך סיום</div>
                            }
                            options={"large"}
                            placeholder={"תאריך סיום"}
                            type={"date"}
                        />
                        <Select
                            options={"large"}
                            label={<div className={styles.label}>תחום</div>}
                            dropdown={artsTypes.map((art) => art.name)}
                            WhenSubmit={(val) =>
                                setArtType(
                                    artsTypes.find((art) => art.name === val).id
                                )
                            }
                            valueIndex={artsTypes.findIndex(
                                (art) => art.id === art_type
                            )}
                        />
                        {subTypes ? (
                            <div className={styles.sub_types_selector}>
                                <p>בחרו את התחומים שיכללו במועד זה</p>
                                {subTypes.map((subType, index) => (
                                    <Checkbox
                                        label={subType.name}
                                        key={index}
                                        value={subType.id}
                                        checked={
                                            !excludeSubTypes.includes(
                                                subType.id
                                            )
                                        }
                                        onChange={handleExcludeSubTypes}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <Button onClick={HandleSubmit} options={"large hover_dark"}>
                        {date_id ? "עריכת" : "הוספת"} תאריך
                    </Button>
                </div>
            )}
        </>
    );
}
