import React, { useState, useEffect } from "react";
import Input from "../../../../comps/input";

export default ({ defaultValue, requestId, disabled, updateFunc }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = async (e) => {
    if (e.target.value === defaultValue) return;
    const sucsess = await updateFunc(e.target.value, requestId);

    if (!sucsess) {
      setValue(defaultValue);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "0 20px",
      }}
    >
      <Input
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onBlur={handleSubmit}
        type="number"
      />
    </div>
  );
};
