import React, { useState, useEffect } from "react";
import { GET } from "../../../../API";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.scss";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import { useCookies } from "react-cookie";

export default function OpenDatesScreen() {
  const GoTo = useNavigate();
  const [cookies, _setCookie, removeCookie] = useCookies(["form_session"]);
  const { category } = useParams();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [openDates, setOpenDates] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const response = await GET(`due-dates/${category}/get-active`);
        const { dates } = await response.json();

        setOpenDates(dates);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (cookies.form_session) {
      checkSession();
    }
  }, [cookies]);

  const checkSession = async () => {
    try {
      const response = await GET(
        `request/check-session?form_session=${cookies.form_session}`
      );

      if (response.status === 200) {
        GoTo(`/${cookies.session_type}/open-dates/form`);
      } else {
        removeCookie("form_session");
        removeCookie("session_type");
      }
    } catch {}
  };

  const formatDate = (date) => new Date(date).toLocaleDateString("he-IL");

  if (loading) return <LoadingScreenWithHeader />;
  if (error) return <>Error</>;
  return (
    <div className={styles.Container}>
      <div className={styles.Cards}>
        {openDates.map((date, index) => (
          <div
            key={index}
            className={styles.Card}
            onClick={() => GoTo(`open-dates/date?date_id=${date.id}`)}
          >
            <h2>{date.name}</h2>
            <div className={styles.Dates}>
              <div className={styles.date_wrapper}>
                <span>תאריך פתיחה</span>
                <span>{formatDate(date.start_date)}</span>
              </div>

              <div className={styles.date_wrapper}>
                <span>תאריך סיום</span>
                <span>{formatDate(date.end_date)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
