import React from "react";
export default function deepCopy(obj) {
  if (React.isValidElement(obj)) {
    return React.cloneElement(obj);
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item));
  }
  if (Object(obj) === obj) {
    let newObj = {};
    Object.keys(obj).forEach((key) => (newObj[key] = deepCopy(obj[key])));
    return newObj;
  }
  return obj;
}
