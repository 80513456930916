import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function Logout() {
  const { token } = useParams();
  const exist_token = localStorage.getItem("token").replace("\n", "");

  useEffect(() => {
    if (token === exist_token) {
      localStorage.removeItem("token");
      window.location.href = "/sign-in";
    } else window.location.href = "/";
  }, [token, exist_token]);

  return <>מתנתק...</>;
}
