import React  from "react";
import styles from "./style.module.scss";
import { BsPatchCheckFill } from "react-icons/bs";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Button from "../../../../../../comps/button";

export default function Success(props) {
  const [_cookies, _setCookie, removeCookie] = useCookies();
  const {request_id} = props;
  const { category } = useParams();
  
  const removeCookies = () => {

    removeCookie("form_session", { path: "/" });
    removeCookie("session_type", { path: "/" });

    window.location.href = `/${category}`;
  };

  return (
    <div className={styles.Container}>
      <h1>הבקשה הוגשה בהצלחה</h1>
      <p>על מנת לוודא כי הבקשה נקלטה במערכת, חובה עליכם לוודא קבלת מייל אישור הגשת הבקשה</p>
      <p>מספר בקשה {request_id || ""}</p>
      <BsPatchCheckFill />
      <br />
      <Button onClick={removeCookies}>
        לדף הבית
      </Button>
    </div>
  );
}
