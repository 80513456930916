import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../comps/header";
import ArtPicker, { CATEGORIES } from "../../comps/ArtPicker";
import { FiLogOut } from "react-icons/fi";
import Requests from "./requests";

export default function ArtManagerController() {
  const { category, route } = useParams();
  const token = localStorage.getItem("token").replace("\n", "");
  const [Links, setLinks] = useState([]);

  const GoTo = useNavigate();

  useEffect(() => {
    setLinks([
      {
        name: <FiLogOut />,
        link: `/logout/${token}`,
      },
      {
        name: "בקשות מימון",
        link: `/${category}/requests`,
      },
      {
        name: "עבור לתחום",
        options: [
          {
            name: "קולנוע",
            link: "/film",
          },
          {
            name: "אמנויות",
            link: "/art",
          },
          {
            name: "אמנים במצוקה",
            link: "/help",
          },
          {
            name: "סיוע להוצאה לאור של ספרים",
            link: "/publish",
          },
        ],
      },
    ]);
    setColor(category);
  }, [category]);

  const setColor = (cat) => {
    CATEGORIES.forEach((item) => {
      if (item.type === cat) {
        document
          .querySelector(":root")
          .style.setProperty("--primary", item.color);
      }
    });
  };

  // user is on /
  if (!category) {
    return <ArtPicker />;
  }

  // user is on /something-that-is-not-film-or-art
  if (!CATEGORIES.some((cat) => cat.type === category))
    return (window.location.href = "/");
  return (
    <>
      <Header links={Links} />
      <main style={{ marginTop: "70px" }}>
        {route === "requests" ? <Requests /> : GoTo(`/${category}/requests`)}
      </main>
    </>
  );
}
