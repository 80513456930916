import styles from "./style.module.scss";
import { MdOutlineClose } from "react-icons/md";

export default function DetailsPopup(props) {
  const { close, data, title } = props;

  const createLink = (href, value) => {
    let res = {};
    switch (href) {
      case "email":
        res.href = `mailto:${value}`;
        break;
      case "tel":
        res.href = `tel:${value}`;
        break;
      default:
        break;
    }
    return res;
  };
  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        <div className={styles.Icon} onClick={close}>
          <MdOutlineClose />
        </div>
        <h2>{title}</h2>
        {data.map((item, index) => (
          <div className={styles.Cell} key={index}>
            <label>{item.label}</label>
            <a {...createLink(item.href, item.value)}>{item.value}</a>
          </div>
        ))}
      </div>
    </div>
  );
}
