import React, { useEffect, useRef, useState } from "react";
import { AiFillFolderOpen, AiOutlineArrowDown } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { GET } from "../../../../API";
import Button from "../../../../comps/button";
import deepCopy from "../../../../comps/deepcopy";
import Error from "../../../../comps/Error";
import Input from "../../../../comps/input";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import WatchLactorReview from "./lactor_review";
import styles from "./style.module.scss";

export default function GetRequestById() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const { category } = useParams();

    const [Headline, setHeadline] = useState("");
    const [SubArt, setSubArt] = useState("");
    const [requestID, setRequestID] = useState("");
    const [lactorsReviews, setlactorsReviews] = useState([]);
    const [Inputs, setInputs] = useState([]);
    const InputsRef = useRef();

    const [finalReviewHeadline, setFinalReviewHeadline] = useState("");

    const [screenError, setScreenError] = useState(false);
    const [screenLoading, setscreenLoading] = useState(false);

    const [CurrStatus, setCurrStatus] = useState("");

    useEffect(() => {
        get_request();
    }, []);

    async function get_request() {
        setscreenLoading(true);
        try {
            const response = await GET(`requests/${category}/by-id?id=${id}`);
            let json = await response.json();

            setHeadline(json.due_date);
            setRequestID(json.request_id);
            setlactorsReviews(json.lactors_reviews);
            setFinalReviewHeadline(json.finall_review_text);
            setSubArt(json.sub_art);

            if (json.passed === true) {
                setCurrStatus("עבר לשלב הבא");
            } else if (json.passed === false) {
                setCurrStatus("לא עבר שלב");
            } else {
                setCurrStatus("ממתין להחלטה");
            };

            // twice for comparing when (if) edited
            let formattedText = formatTextFields(json.text, json.files);

            setInputs(deepCopy(formattedText));
            InputsRef.current = deepCopy(formattedText);
        } catch {
            setScreenError(true);
        } finally {
            setscreenLoading(false);
        };
    };

    function formatTextFields(fields, files) {
        fields = Object.keys(fields).map((key) => {
            let item = {
                key,
                order: fields[key].order,
                placeholder: fields[key].hebrew_name,
                label: fields[key].hebrew_name,
                type: fields[key].type,
                status: "",
                helper_text: "",
                value: fields[key].value || "",
            };
            return item;
        });

        files = Object.keys(files).map((key) => {
            let item = {
                key,
                order: files[key].order,
                type: "file",
                placeholder: files[key].hebrew_name,
                amount: files[key].amount ?? 1,
            };
            return item;
        });

        return [...fields, ...files].sort((a, b) => a.order - b.order);
    }

    const formatFilePlaceholder = (amount) => {
        switch (amount) {
            case 0:
                return `אין קבצים`;
            case 1:
                return `קובץ אחד בשדה זה`;
            default:
                return `${amount} קבצים בשדה זה`;
        }
    };

    const OpenPopup = (index) => {
        let newLacts = [...lactorsReviews];
        newLacts[index].show = !newLacts[index].show;
        setlactorsReviews(newLacts);
    };

    if (screenError)
        return (
            <Error
                text={`אירעה שגיאה`}
                description={`אירעה שגיאה בטעית פרטי הבקשה, יכול להיות שהבקשה לא קיימת, או שהיא נמחקה.`}
            />
        );

    if (screenLoading) return <LoadingScreenWithHeader />;

    return (
        <div className={styles.Container}>
            <h1>{Headline}</h1>
            <h3>{SubArt}</h3>
            <h4>{requestID}</h4>
            <h4>{CurrStatus}</h4>
            <div className={styles.Lactors}>
                {lactorsReviews.length ? (
                    lactorsReviews.map((review, index) => (
                        <div key={index}>
                            <div className={styles.preview}>
                                <div className={styles.Lactor}>
                                    <span>{review.full_name}</span>
                                    <span>
                                        {review.submitted ? "✔️" : "❌"}
                                    </span>
                                </div>
                                <Button
                                    options={"square small hover_dark"}
                                    onClick={() => OpenPopup(index)}
                                >
                                    <AiOutlineArrowDown />
                                </Button>
                            </div>
                            <div hidden={!review.show}>
                                <WatchLactorReview
                                    GradeTable={review.grade_table}
                                    name={review.full_name}
                                    finalReviewHeadline={finalReviewHeadline}
                                    finalReview={review.finall_review}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.Lactors}>אין לקטורים לבקשה זו</div>
                )}
            </div>

            <div className={styles.Inputs}>
                {Inputs.map((input, index) => {
                    if (input.type === "textarea" || input.type === "select") {
                        return (
                            <Input
                                type={"textarea"}
                                key={index}
                                name={input.key}
                                value={input.value}
                                placeholder={input.placeholder}
                                label={input.value ? input.label : ""}
                                status={input.status}
                                helper_text={input.helper_text}
                                disabled={true}
                            />
                        );
                    } else if (input.type === "url") {
                        return (
                            <Input
                                key={index}
                                name={input.key}
                                value={input.value || ""}
                                placeholder={input.placeholder}
                                type={input.type}
                                label={input.value ? input.label : ""}
                                status={input.status}
                                helper_text={input.helper_text}
                                disabled={true}
                                icon={
                                    <a
                                        href={input.value}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                    >
                                        <FaLink />
                                    </a>
                                }
                            />
                        );
                    } else if (input.type === "file") {
                        return (
                            <Input
                                key={index}
                                name={input.key}
                                label={input.placeholder}
                                value={formatFilePlaceholder(input.amount)}
                                disabled={true}
                                icon={
                                    <a
                                        href={`/${category}/view_file?main_id=${id}&file_key=${input.key}`}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                    >
                                        <AiFillFolderOpen />
                                    </a>
                                }
                            />
                        );
                    } else {
                        return (
                            <Input
                                key={index}
                                name={input.key}
                                value={input.value || ""}
                                placeholder={input.placeholder}
                                type={input.type}
                                label={input.value ? input.label : ""}
                                status={input.status}
                                helper_text={input.helper_text}
                                disabled={true}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
};
