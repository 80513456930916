import React from "react";
import styles from "./style.module.scss";

/**
 * @param children {string} text to display
 * @param {string} progress -> 55%
 */

export default function ProgressBar({ progress, children }) {
  return <div className={styles.ProgressBar} style={{ "--progress": progress }}>{children}</div>;
}