import { useParams } from "react-router-dom";
import OpenDatesScreen from "./open_dates";
import GetDate from "./get_date";
import Form from "./form";
import Error404 from "../../auth/404";
import React from "react";

export default function OpenDates() {
  const { action } = useParams();

  if (!action) {
    return <OpenDatesScreen />;
  } else if (action === "date") {
    return <GetDate />;
  } else if (action === "form") {
    return <Form />;
  }
  return <Error404 />;
}
