import { useParams } from "react-router-dom";
import AllUsers from "./all_users";
import PutUser from "./put_user";
import React from "react";

export default function UsersController() {
  const { action } = useParams();

  switch (action) {
    
    case undefined: // importent !!
      return <AllUsers />;
    case "manage":
      return <PutUser />;
    default:
      return <>404</>;
  }
}
