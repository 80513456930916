import styles from "./style.module.scss";

const CATEGORIES = [
  {
    name: "קולנוע",
    link: "/film",
    color: "brown",
    type: "film",
  },
  {
    name: "אמנויות",
    link: "/art",
    color: "#073762",
    type: "art",
  },
  {
    name: "אמנים במצוקה",
    link: "/help",
    color: "purple",
    type: "help",
  },
  {
    name: "סיוע להוצאה לאור של ספרים",
    link: "/publish",
    color: "#D2691E",
    type: "publish",
  },
];

export default function ArtPicker({ extendedLink }) {
  return (
    <div className={styles.Container}>
      {CATEGORIES.map((category, index) => (
        <div key={index} style={{ "--color": category.color }}>
          <a href={category.link}>{category.name}</a>
        </div>
      ))}
    </div>
  );
}

export { CATEGORIES };
