import React, { useRef, useState, useEffect } from "react";
import SignAture from "../../../../../../comps/signingCanves";
import styles from "./style.module.scss";
import Button from "../../../../../../comps/button";
import { LoadingScreenWithHeader } from "../../../../../../comps/loading";

export default function FormStep4({ fileLink, submitStep }) {
  const [size, setSize] = useState();
  const [startSigning, setStartSigning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef();
  
  const handleSubmit = () => {
    setIsLoading(true);
    const img = canvasRef.current.getImg();
    submitStep({doc: [{ name: "signature", value: img }]});
  };

  const handleClean = () => {
    canvasRef.current.clear();
  };

  useEffect(() => {
    handleSetSize();
    window.addEventListener("resize", handleSetSize);
  }, []);

  const getScreenSize = () => window.innerWidth;

  const handleSetSize = () => {
    const size = getScreenSize();
    if (size < 1000) {
      setSize(100);
    } else if (size < 1500) {
      setSize(150);
    } else {
      setSize(200);
    }
  };

  if (isLoading) {
    return <LoadingScreenWithHeader />;
  };

  return (
    <div className={styles.Container}>
      <b><mark>נא לחתום בסוף העמוד</mark></b>
      <embed src={fileLink}></embed>
      <div className={styles.signature} style={{ direction: "ltr"}}>
        <u><b>חתימה</b></u>
        <Button options="x_x_small" onClick={handleClean} disabled={!startSigning}>איפוס חתימה</Button>
        <SignAture ref={canvasRef} width={(3 * size) + "px"} height={(1 * size)+ "px"} onClick={() => setStartSigning(true)} onTouchStart={() => setStartSigning(true)}/>
        <Button options="small" onClick={handleSubmit} disabled={!startSigning}>סיום הגשה</Button>
        
      </div>
    </div>
  );
}
