import React, { useState, useEffect } from "react";
import NewSelect from "../../../../comps/newSelect";

const ReqStatusOptions = [
  { name: "כן", id: 1 },
  { name: "לא", id: 2 },
  { name: "ממתין", id: 3 },
];

export default ({ defaultId, requestId, disabled, updateFunc }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultId);
  }, [defaultId]);

  const handleChange = async (e) => {
    const intialValue = value;
    setValue(e.target.value);
    const sucsess = await updateFunc(e.target.value, requestId);

    if (!sucsess) {
      setValue(intialValue);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "0 20px",
      }}
    >
      <NewSelect
        options={ReqStatusOptions.map((item) => ({
          value: item.name,
          key: item.id,
        }))}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};
