import { useState } from "react";
import Loading from "../../../loading";
import styles from "../../style.module.css";

export default function Content({ Data, Thead, Sort, loading }) {
  const [SortedArrow, setSortedArrow] = useState(["", true]);

  const RenderCell = (val) => {
    // if val is undefined, return empty string
    // if val is string, number or boolean, we're good
    // if val is an object -> try val.html, then try val.value, else return an empty string

    if (typeof val === "undefined") return "";

    if (typeof val === "object") {
      if (typeof val.html !== "undefined") {
        return val.html;
      }
      if (typeof val.value !== "undefined") {
        return val.value;
      }
      return "";
    }
    if (
      typeof val === "string" ||
      typeof val === "number" ||
      typeof val === "boolean"
    )
      return <div className={styles.RegularCell}>{val}</div>;

    return "";
  };

  const HandleSort = (key) => {
    Sort(key);
    setSortedArrow([key, !SortedArrow[1]]);
  };

  return (
    <div className={styles.Rows}>
      <div className={`${styles.Row} ${styles.Thead}`}>
        {Object.keys(Thead).map((key, index) => (
          <div
            key={index}
            onClick={() => HandleSort(key)}
            style={{ width: "100px", ...Thead[key].style }}
          >
            <span>{Thead[key].label}</span>
            <div className={styles.Arrows}>
              <div
                className={
                  SortedArrow[0] === key && SortedArrow[1] ? styles.Active : ""
                }
              ></div>
              <div
                className={
                  SortedArrow[0] === key && !SortedArrow[1] ? styles.Active : ""
                }
              ></div>
            </div>
          </div>
        ))}
      </div>
      {loading ? (
        <LoadingComp />
      ) : !Data.length ? (
        <div className={styles.CenterInTable}>
          <span>אין נתונים</span>
        </div>
      ) : (
        Data.map((Row, RowIndex) => (
          <div className={styles.Row} key={RowIndex}>
            {Object.keys(Thead).map((Cell, CellIndex) => (
              <div
                key={CellIndex}
                className={styles.Cell}
                style={{ width: "100px", ...Thead[Cell].style }}
              >
                {RenderCell(Row[Cell])}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}
const LoadingComp = () => (
  <div className={styles.CenterInTable}>
    <Loading />
  </div>
);
