import Rect from 'react';
import styles from "./style.module.scss";

export default function NewSelect({ options=[], onChange, value, name, error, defaultValue, disabled }) {
    return (
        <div className={styles.select__wrraper}>
            <select onChange={onChange} value={value} name={name} defaultValue={defaultValue} disabled={disabled}>
                {options.map((option, index) => (
                    <option key={index} value={option.key || option.value}>{option.value}</option>
                ))}
            </select>
            {error && 
            
            <span>error</span>
            }
        </div>
    );
}