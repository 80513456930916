import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { GET, POST } from "../../../../API";
import Button from "../../../../comps/button";
import Dialog from "../../../../comps/dialog";
import Error from "../../../../comps/Error";
import Input from "../../../../comps/input";
import Loading from "../../../../comps/loading";
import Notification from "../../../../comps/Notification";
import { default as Config } from "../../../../config";
import styles from "./style.module.scss";
import { RiMailCheckFill, RiMailCloseFill } from "react-icons/ri";

export default function Dates() {
  const { category } = useParams();
  const GoTo = useNavigate();
  const [Dates, setDates] = useState([]);
  const [DatesErr, setDatesErr] = useState(false);
  const [search, setSearch] = useState("");

  const [LoadingMore, setLoading] = useState(false);
  const [Total, setTotal] = useState(0);

  const [theDialog, setTheDialog] = useState();
  const [Notif, setNotif] = useState();

  useEffect(() => {
    getDates();
  }, [search]);

  const getDates = async (loadMore = false) => {
    try {
      setLoading(true);

      const AMOUNT = 20;
      // dates / 20 = the next page to load
      let link;
      if (loadMore) {
        link = `due-dates/${category}/all?amount=${AMOUNT}&page=${
          Dates.length / AMOUNT
        }&search=${search}`;
      } else {
        link = `due-dates/${category}/all?amount=${AMOUNT}&page=0&search=${search}`;
      }

      const response = await GET(link);
      const { dates, total } = await response.json();

      if (loadMore) {
        setDates([...Dates, ...dates]);
      } else {
        setDates(dates);
      }
      setTotal(total);
    } catch {
      setDatesErr(true);
      sendNotification("אירעה שגיאה בטעינת הנתונים");
    } finally {
      setLoading(false);
    }
  };

  function formatDate(date) {
    date = new Date(date);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  // const HandleDelete = (index) => {
  //   if (Notif || theDialog) return;
  //   setTheDialog({
  //     text: "המון מידע ימחק, אתם בטוחים?",
  //     onYes: () => deleteDate(index),
  //     onNo: () => setTheDialog(),
  //   });
  // };

  const HandleSendGoodEamils = (index) => {
    if (Notif || theDialog) return;
    setTheDialog({
      text: `לשלוח מיילים לבקשות מימון שאושרו במועד זה?`,
      onYes: () => sendGoodEmails(index),
      onNo: () => setTheDialog(),
    });
  };

  const HandleSendBadEamils = (index) => {
    if (Notif || theDialog) return;
    setTheDialog({
      text: `לשלוח מיילים לבקשות מימון שלא אושרו במועד זה?`,
      onYes: () => sendBadEmails(index),
      onNo: () => setTheDialog(),
    });
  };

  const sendGoodEmails = async (index) => {
    setTheDialog();
    try {
      let date_id = Dates[index].id;
      const response = await POST(
        `send-emails/${category}/accepted-requests?date_id=${date_id}`
      );

      if (response.status === 200) {
        sendNotification("המיילים נשלחו בהצלחה");
      } else throw new Error();
    } catch {
      sendNotification("אירעה שגיאה בשליחת המיילים");
    }
  };
  const sendBadEmails = async (index) => {
    setTheDialog();
    try {
      let date_id = Dates[index].id;
      const response = await POST(
        `send-emails/${category}/declined-requests?date_id=${date_id}`
      );

      if (response.status === 200) {
        sendNotification("המיילים נשלחו בהצלחה");
      } else throw new Error();
    } catch {
      sendNotification("אירעה שגיאה בשליחת המיילים");
    }
  };

  // const deleteDate = async (index) => {
  //   setTheDialog();

  //   try {
  //     const response = await DELETE(
  //       `due-dates/delete?date_id=${Dates[index].id}`
  //     );

  //     if (response.status === 200) {
  //       setDates(Dates.filter((_, i) => i !== index));
  //       setTotal(Total - 1);
  //       sendNotification("התאריך נמחק בהצלחה");
  //     } else {
  //       throw new Error();
  //     }
  //   } catch {
  //     sendNotification("ארעה שגיאה במחיקת התאריך");
  //   }
  // };

  const HandleManageReviews = async (index) => {
    if (Notif) return;
    try {
      const response = await POST(
        `due-dates/${
          Dates[index].open_reviews ? "close" : "open"
        }-reviews?date_id=${Dates[index].id}`
      );

      if (response.status === 200) {
        setDates(
          Dates.map((date, i) => {
            if (i === index) {
              return {
                ...date,
                open_reviews: !date.open_reviews,
              };
            }
            return date;
          })
        );
        sendNotification("עודכן בהצלחה");
      } else {
        throw new Error();
      }
    } catch {
      sendNotification("אירעה שגיאה בעדכון הפרטים");
    }
  };

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(""), 3000);
  };

  const GoToEdit = (index) => {
    GoTo(`manage_date?date_id=${Dates[index].id}`);
  };

  if (DatesErr) return <Error description={"אירעה שגיאה בטעינת התאריכים"} />;
  return (
    <div className={styles.container}>
      {theDialog && <Dialog {...theDialog} />}
      {Notif && <Notification html={Notif} />}
      <h1>תאריכים ({Config[category]})</h1>

      <div className={styles.SearchBar}>
        <Input
          placeholder={"חיפוש תאריכים"}
          icon={<GoSearch />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          options={"square hover_dark"}
          onClick={() => GoTo(`manage_date`)}
        >
          <FaPlus />
        </Button>
      </div>
      <div className={styles.Cards}>
        {Dates.map((date, index) => (
          <div key={index} className={styles.Card}>
            <div className={styles.Info}>
              <h2 onClick={() => GoToEdit(index)}>{date.name}</h2>
              <div className={styles.Dates}>
                <span>{formatDate(date.start_date)}</span>
                <BsArrowLeft />
                <span>{formatDate(date.end_date)}</span>
              </div>
            </div>
            <div className={styles.Btns}>
              <Button
                options={"small square hover_dark"}
                onClick={() => HandleSendGoodEamils(index)}
              >
                <RiMailCheckFill />
              </Button>
              <Button
                options={"small square hover_dark"}
                onClick={() => HandleSendBadEamils(index)}
              >
                <RiMailCloseFill />
              </Button>
              <Button
                options={"small hover_dark"}
                onClick={() => HandleManageReviews(index)}
              >
                {date.open_reviews ? "סגירת" : "פתיחת"} ביקורות
              </Button>
            </div>
          </div>
        ))}
      </div>
      {LoadingMore ? (
        <Loading />
      ) : Total > Dates.length ? (
        <button className={styles.LoadMore} onClick={() => getDates(true)}>
          לטעון עוד...
        </button>
      ) : null}
    </div>
  );
}
