const Config = {
  lactor: "לקטור",
  admin: "מנהל על",
  user: "משתמש",
  art_manager: "מנהל אמנות",

  film: "קולנוע",
  art: "אמנות",
  help: "תמיכה באמנים",
  publish: "סיוע להוצאה לאור של ספרים",
};

export default Config;
