const Thead = {
  film: {
    request_id: {
      label: "#",
      style: { width: "120px", padding: "0px 10px" },
    },
    pdf: {
      label: "pdf",
      style: { width: "80px", padding: "0px 10px" },
    },
    project_name: {
      label: "שם פרויקט",
      style: { width: "200px" },
    },
    form_sender: {
      label: "שם מגיש",
      style: { width: "200px" },
    },
    sub_art: {
      label: "תחום הגשה",
      style: {
        width: "400px",
        marginLeft: "15px",
        textOverflow: "ellipsis",
      },
    },
    lactors_reviews: {
      label: "לקטורים",
      style: { width: "200px" },
    },
    director: {
      label: "בימוי",
      style: { width: "150px" },
    },
    producers: {
      label: "הפקה",
      style: { width: "150px" },
    },
    passed: {
      label: "עבר שלב",
      style: { width: "150px" },
    },
    final_answer: {
      label: "תשובה סופית",
      style: { width: "150px" },
    },
    approved_funding: {
      label: "סכום מימון",
      style: { width: "150px" },
    },
    sending_mail: {
      label: "מייל העברת שלב",
      style: { width: "150px" },
    },
  },
  art: {
    request_id: {
      label: "#",
      style: { width: "200px", padding: "0px 10px" },
    },
    pdf: {
      label: "pdf",
      style: { width: "80px", padding: "0px 10px" },
    },
    project_name: {
      label: "שם פרויקט",
      style: { width: "200px" },
    },
    budget: {
      label: "תקציב ההפקה",
      style: { width: "150px" },
    },
    first_name: {
      label: "שם פרטי",
      style: { width: "200px" },
    },
    last_name: {
      label: "שם משפחה",
      style: { width: "200px" },
    },
    applicant: {
      label: "המבקש",
      style: { width: "200px" },
    },
    production_date: {
      label: "תאריך הוצאה",
      style: { width: "200px" },
    },
    lactors_reviews: {
      label: "לקטורים",
      style: { width: "200px" },
    },
    passed: {
      label: "עבר שלב",
      style: { width: "150px" },
    },
    final_answer: {
      label: "תשובה סופית",
      style: { width: "150px" },
    },
    approved_funding: {
      label: "סכום מימון",
      style: { width: "150px" },
    },
    sending_mail: {
      label: "מייל העברת שלב",
      style: { width: "150px" },
    },
  },
  publish: {
    request_id: {
      label: "#",
      style: { width: "200px", padding: "0px 10px" },
    },
    pdf: {
      label: "pdf",
      style: { width: "80px", padding: "0px 10px" },
    },
    project_name: {
      label: "שם פרויקט",
      style: {
        width: "200px",
        marginLeft: "15px",
        textOverflow: "ellipsis",
      },
    },
    applicant: {
      label: "המבקש",
      style: { width: "200px" },
    },
    sub_art: {
      label: "תחום הגשה",
      style: {
        width: "400px",
        marginLeft: "15px",

        textOverflow: "ellipsis",
      },
    },
    author_name: {
      label: "שם הסופר",
      style: {
        width: "200px",
        marginLeft: "15px",

        textOverflow: "ellipsis",
      },
    },
    production_date: {
      label: "תאריך הוצאה",
      style: { width: "200px" },
    },
    lactors_reviews: {
      label: "לקטורים",
      style: { width: "200px" },
    },
    passed: {
      label: "עבר שלב",
      style: { width: "150px" },
    },
    final_answer: {
      label: "תשובה סופית",
      style: { width: "150px" },
    },
    approved_funding: {
      label: "סכום מימון",
      style: { width: "150px" },
    },
    sending_mail: {
      label: "מייל העברת שלב",
      style: { width: "150px" },
    },
  },
  help: {
    request_id: {
      label: "#",
      style: { width: "200px", padding: "0px 10px" },
    },
    pdf: {
      label: "pdf",
      style: { width: "80px", padding: "0px 10px" },
    },
    first_name: {
      label: "שם פרטי",
      style: { width: "200px" },
    },
    last_name: {
      label: "שם משפחה",
      style: { width: "200px" },
    },
    sub_art: {
      label: "תחום הגשה",
      style: {
        width: "400px",
        marginLeft: "15px",

        textOverflow: "ellipsis",
      },
    },
    artist_activity_type: {
      label: "תחום עיסוק באמנות",
      style: { width: "200px" },
    },
    birth_year: {
      label: "שנת לידה",
      style: { width: "200px" },
    },
    number_of_kids: {
      label: "מספר ילדים",
      style: { width: "200px" },
    },
    lactors_reviews: {
      label: "לקטורים",
      style: { width: "200px" },
    },
    passed: {
      label: "עבר שלב",
      style: { width: "150px" },
    },
    final_answer: {
      label: "תשובה סופית",
      style: { width: "150px" },
    },
    approved_funding: {
      label: "סכום מימון",
      style: { width: "150px" },
    },
    sending_mail: {
      label: "מייל העברת שלב",
      style: { width: "150px" },
    },
  },
};

const searchInputs = {
  film: [
    {
      name: "project_name",
      type: "text",
      placeholder: "שם פרויקט",
    },
    {
      name: "art_manager",
      type: "selectManager",
      placeholder: "מנהל אומנות",
    },
    {
      name: "sub_art",
      type: "select",
      placeholder: "תחום הגשה",
    },
    {
      name: "form_sender",
      type: "text",
      placeholder: "שם מגיש",
    },
    {
      name: "request_id",
      type: "number",
      placeholder: "מספר בקשה",
    },
    {
      name: "director",
      type: "text",
      placeholder: "בימוי",
    },
    {
      name: "producers",
      type: "text",
      placeholder: "הפקה",
    },
    {
      name: "lactors_reviews",
      type: "text",
      placeholder: "לקטורים",
    },
    {
      name: "final_answer",
      type: "check-box",
      placeholder: "תשובה סופית",
    },
    {
      name: "passed",
      type: "check-box",
      placeholder: "עבר שלב",
    },
  ],
  art: [
    {
      name: "project_name",
      type: "text",
      placeholder: "שם פרויקט",
    },
    {
      name: "applicant",
      type: "text",
      placeholder: "המגיש",
    },
    {
      name: "first_name",
      type: "text",
      placeholder: "שם פרטי"
    },
    {
      name: "last_name",
      type: "text",
      placeholder: "שם משפחה"
    },
    {
      name: "request_id",
      type: "number",
      placeholder: "מספר בקשה",
    },
    {
      name: "lactors_reviews",
      deafultValue: "",
      value: "",
      type: "text",
      placeholder: "לקטורים",
    },
    {
      name: "final_answer",
      type: "check-box",
      placeholder: "תשובה סופית",
    },
    {
      name: "passed",
      type: "check-box",
      placeholder: "עבר שלב",
    },
  ],
  publish: [
    {
      name: "project_name",
      type: "text",
      placeholder: "שם פרויקט",
    },
    {
      name: "applicant",
      type: "text",
      placeholder: "המבקש",
    },
    {
      name: "sub_art",
      type: "select",
      placeholder: "תחום הגשה",
    },
    {
      name: "request_id",
      type: "number",
      placeholder: "מספר בקשה",
    },
    {
      name: "author_name",
      type: "text",
      placeholder: "שם הסופר",
    },
    {
      name: "lactors_reviews",
      deafultValue: "",
      value: "",
      type: "text",
      placeholder: "לקטורים",
    },
    {
      name: "final_answer",
      type: "check-box",
      placeholder: "תשובה סופית",
    },
    {
      name: "passed",
      type: "check-box",
      placeholder: "עבר שלב",
    },
  ],
  help: [
    {
      name: "first_name",
      type: "text",
      placeholder: "שם פרטי",
    },
    {
      name: "last_name",
      type: "text",
      placeholder: "שם משפחה",
    },
    {
      name: "artist_activity_type",
      type: "text",
      placeholder: "תחום עיסוק באמנות",
    },
    {
      name: "request_id",
      type: "number",
      placeholder: "מספר בקשה",
    },
    {
      name: "birth_year",
      type: "text",
      placeholder: "שנת לידה",
    },
    {
      name: "lactors_reviews",
      type: "text",
      placeholder: "לקטורים",
    },
    {
      name: "final_answer",
      type: "check-box",
      placeholder: "תשובה סופית",
    },
    {
      name: "passed",
      type: "check-box",
      placeholder: "עבר שלב",
    },
  ],
};

export { Thead, searchInputs };
