import styles from "./style.module.scss";

export default function Dialog(props) {
  const { text, onYes, onNo } = props;

  const HandleOnNo = (e) => {
    if (e.target.classList.value.includes("Container")) onNo();
  };
  return (
    <div className={styles.Container} onClick={HandleOnNo}>
      <div className={styles.Dialog}>
        <div>{text}</div>
        <div>
          <button onClick={onYes}>מאשר</button>
          <button onClick={onNo}>לא מאשר</button>
        </div>
      </div>
    </div>
  );
}
