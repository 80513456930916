import Error from "../../../comps/Error";
import Button from "../../../comps/button";
import React from "react";

export default function Error404() {
  return (
    <Error
      text={"404"}
      description={"דף זה אינו קיים"}
      btn={
        <Button onClick={() => (window.location.href = "/")}>
          חזרה לחוף מבטחים
        </Button>
      }
    />
  );
}
