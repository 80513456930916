import React, { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { GET } from "../../../../API";
import styles from "./style.module.scss";
import Table from "../../../../comps/table";
import deepCopy from "../../../../comps/deepcopy";
import Error from "../../../../comps/Error";
import { BsSearch } from "react-icons/bs";
import Input from "../../../../comps/input";
import { GrDocumentPdf } from "react-icons/gr";
import Button from "../../../../comps/button";

const Thead = {
    art: {
        review_id: {
            label: "#",
            style: { width: "200px", padding: "0px 10px" },
        },
        script_name: {
            label: "שם פרויקט",
            style: { width: "200px" },
        },
        applicant: {
            label: "שם מלא",
            style: { width: "200px" },
        },
        last_name: {
            label: "שם משפחה",
            style: { width: "200px" },
        },
        first_name: {
            label: "שם פרטי",
            style: { width: "200px" },
        },
        pdf: {
          label: "pdf",
          style: { width: "80px", padding: "0px 10px" },
        },
        date: {
            label: "תאריך סיום חוות הדעת",
            style: { width: "200px" },
        },
        submitted: {
            label: "חוות דעת הושלמה",
            style: { width: "200px" },
        },
        finall_review: {
            label: "סיכום הביקורת",
            style: { width: "400px", overflow: "hidden" },
        },
    },
    film: {
        review_id: {
            label: "#",
            style: { width: "200px", padding: "0px 10px" },
        },
        script_name: {
            label: "שם פרויקט",
            style: { width: "200px" },
        },
        date: {
            label: "תאריך סיום חוות הדעת",
            style: { width: "200px" },
        },
        submitted: {
            label: "חוות דעת הושלמה",
            style: { width: "200px" },
        },
        finall_review: {
            label: "סיכום הביקורת",
            style: { width: "400px", overflow: "hidden" },
        },
    },
    publish: {
        review_id: {
            label: "#",
            style: { width: "200px", padding: "0px 10px" },
        },
        script_name: {
            label: "שם פרויקט",
            style: { width: "200px" },
        },
        date: {
            label: "תאריך סיום חוות הדעת",
            style: { width: "200px" },
        },
        submitted: {
            label: "חוות דעת הושלמה",
            style: { width: "200px" },
        },
        finall_review: {
            label: "סיכום הביקורת",
            style: { width: "400px", overflow: "hidden" },
        },
    },
    help: {
        review_id: {
            label: "#",
            style: { width: "200px", padding: "0px 10px" },
        },
        script_name: {
            label: "שם פרויקט",
            style: { width: "200px" },
        },
        date: {
            label: "תאריך סיום חוות הדעת",
            style: { width: "200px" },
        },
        submitted: {
            label: "חוות דעת הושלמה",
            style: { width: "200px" },
        },
        finall_review: {
            label: "סיכום הביקורת",
            style: { width: "400px", overflow: "hidden" },
        },
    },
};

export default function Reviews() {
    const [searchParams] = useSearchParams();
    const { category } = useParams();

    const [isLoading, setIsLoading] = useState();
    const [search, setSearch] = useState("");
    const [ExpectedLength, setExpectedLength] = useState(1);
    const [Data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [amount, setAmount] = useState(10);
    const [screenError, setScreenError] = useState(false);

    const RowsInPage = 10;
    const PaginationVal = 0;

    const dateId = searchParams.get("id") || "";

    const handlePdfDownload = async (requestId) => {
        try {
            const response = await GET(
                `v1/founding-requests/${category}/pdf?req_id=${requestId}`
            );
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        } catch {
        }
    };

    useEffect(() => {
        if (!dateId) return;
        getReviews();
    }, [dateId]);

    useEffect(() => {
        // onPagination(), but not onInit(), get data
        if (!dateId) return;
        getReviews();
    }, [page, amount]);

    const formatReviews = (reviews) =>
        reviews.map((review) => {
            review.script_name = {
                value: review.script_name,
                html: (
                    <a
                        className={styles.Center}
                        href={`form?id=${review.the_request}`}
                    >
                        {review.script_name}
                    </a>
                ),
            };
            if (review.date) {
                review.date = {
                    value: new Date(review.date).toLocaleDateString(),
                    html: (
                        <div className={styles.Center}>
                            {new Date(review.date).toLocaleDateString()}
                        </div>
                    ),
                };
            }
            review.submitted = {
                value: review.submitted,
                html: (
                    <div className={styles.Center}>
                        {review.submitted ? "כן" : "לא"}
                    </div>
                ),
            };
            review.pdf = {
                html: (
                    <Button
                        onClick={() => handlePdfDownload(review.the_request)}
                        className={styles.pdfBtn}
                    >
                        <GrDocumentPdf />
                    </Button>
                ),
            };
            return review;
        });

    const getReviews = async (replaceData = false) => {
        let expectedArr = Data.slice(amount * page, amount * page + amount);

        // if data exists in the table, dont load it..
        if (
            !expectedArr.includes(undefined) &&
            expectedArr.length &&
            !replaceData
        )
            return;
        try {
            setIsLoading(true);
            // its not really a post request, more like a GET request with body
            const response = await GET(
                `get-reviews/my/${category}?date_id=${dateId}&search=${search}&amount=${amount}&page=${page}`
            );

            let { reviews, total, error } = await response.json();

            if (error) {
                throw new Error();
            }

            reviews = formatReviews(deepCopy(reviews));

            setExpectedLength(total);

            let newData;

            if (replaceData) {
                newData = Array(total);
                reviews.forEach(
                    (review, index) => (newData[page * amount + index] = review)
                );
            } else {
                newData = Array(total);
                Data.forEach((review, index) => {
                    if (review) {
                        newData[index] = review;
                    }
                });
                reviews.forEach(
                    (review, index) => (newData[amount * page + index] = review)
                );
            }

            setData(newData);
        } catch {
            setScreenError(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (screenError) return <Error />;

    return (
        <div className={styles.Container}>
            <Input
                placeholder={`חפש`}
                onChange={(e) => {
                    setSearch(e.target.value);
                    getReviews(true);
                }}
                icon={<BsSearch />}
            />
            <Table
                hideSearchbar={true}
                Headline={
                    <div className={styles.TableHeadline}>
                        <h2>הגשות ({ExpectedLength || 0})</h2>
                    </div>
                }
                Data={Data}
                Thead={Thead[category]}
                RowsInPage={RowsInPage}
                PaginationVal={PaginationVal}
                Loading={isLoading}
                Lazy={{
                    ExpectedLength,
                    onPagination: (index, RowsInPage) => {
                        setPage(index - 1);
                        setAmount(RowsInPage);
                    },
                    onFilter: (val) => {},
                }}
            />
        </div>
    );
}
