// This component represents individual form fields. 
// It will be used by the FormBuilder component to render each field and handle interactions such as drag-and-drop.

// This component will render individual form fields based on their type. 
// It will handle interactions such as drag-and-drop for reordering fields.

import React from 'react';
import styles from "../style.module.scss";

const FileField = ({ field, removeField, updateFieldProperties }) => {
  const handleRemoveField = () => {
    removeField(field.id);
  };

  const handleUpdateField = (property, value) => {
    const updatedProperties = { [property]: value };
    updateFieldProperties(field.id, updatedProperties);
  };

  return (
    <div className={styles.inputField}>
      <h3 className={styles.titleField}>{field.hebrewLabel}</h3>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-key`}>מזהה שדה:</label>
        <input
          id={`field-${field.key}-key`}
          type="text"
          value={field.key}
          onChange={(e) => handleUpdateField('key', e.target.value)}
          className='style_inp__eLH4y'
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-hebrew-label`}>שם בעברית:</label>
        <input
          id={`field-${field.key}-hebrew-label`}
          type="text"
          value={field.hebrewLabel}
          onChange={(e) => handleUpdateField('hebrewLabel', e.target.value)}
          className='style_inp__eLH4y'
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-required`}>שדה חובה:</label>
        <input
          id={`field-${field.key}-required`}
          type="checkbox"
          checked={field.required}
          onChange={(e) => handleUpdateField('required', e.target.checked)}
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-comment`}>הערות:</label>
        <input
          id={`field-${field.key}-comment`}
          type="text"
          value={field.hebrewLabel}
          onChange={(e) => handleUpdateField('comment', e.target.checked)}
          className='style_inp__eLH4y'
        />
      </div>
      <div className={styles.CardsWrraper}>
        <button onClick={handleRemoveField} className={styles.Btn + " style_btn__APPaZ.style_small__nlrzy"}>מחק שדה</button>
      </div>
    </div>
  );
};

export default FileField;