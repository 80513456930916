import React, { Component } from "react";
import styles from "./style.module.scss";
import Logo from "../../../assets/images/Film-Logo.png";

import Loading from "../../../comps/loading";
import StepOne from "./parts/Step_one";
import StepTwo from "./parts/Step_two";
import StepThree from "./parts/Step_three";

import { POST } from "../../../API";

const Errors = {
  email: {
    400: "תיבת מייל לא תקינה",
  },
  password: {
    400: "סיסמה לא תקינה",
    404: "מייל או סיסמה שגויים",
  },
  code: {
    400: "קוד לא תקין",
    404: "ממ.. זה לא הקוד",
    410: "לא בתוקף, נסה שוב.",
  },
  new_password: {
    400: "סיסמא צריכה להיות באורך של 8 תווים לפחות אות אחת קטנה אות אחת גדולה ספרה ותו מיוחד",
    404: "מייל או סיסמה שגויים",
  },
};

function validatePassword(password) {
  var pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!"\#\$%\&'\(\)\*\+,\-\./:;<=>\?@\[\\\]\^_`\{\|\}\~]).{8,64}$/;

  if (pattern.test(password)) {
    // Password is valid
    return true;
  } else {
    // Password is invalid
    return false;
  }
}

class SignIn extends Component {
  state = {
    step: 0,
    loading: false,
    error: false,

    // input fields, StepOne
    email: { value: "", status: "", helper_text: "" },
    password: { value: "", status: "", helper_text: "" },
    stay: { value: true, status: "", helper_text: "" },
    tel: { value: true },

    // sms field, StepTwo
    code: {
      value: "",
      status: "",
      helper_text: "",
      label: "קוד האימות תקף ל5 דקות",
    },

    // input fields, StepThree
    new_password: { value: "", status: "", helper_text: "" },
    new_password_again: { value: "", status: "", helper_text: "" },
  };

  writeData = (data, key) => {
    if (key === "code") {
      this.setState({ [key]: { ...this.state[key], value: data } });
    } else {
      this.setState({
        [key]: { ...this.state[key], value: data, helper_text: "", status: "" },
      });
    }
  };

  readData = (key) => this.state[key];

  submitFirstStep = async () => {
    this.setState({ loading: true });

    if (!validatePassword(this.state.password.value)) {
      return this.setState({
        password: {
          ...this.state.password,
          status: "error",
          helper_text: Errors["new_password"][400],
        },
        loading: false,
      });
    }

    let doc = {
      email: this.state.email.value,
      password: this.state.password.value,
      tel: this.state.tel.value,
    };

    try {
      const response = await POST("v1/login", doc);
      // const response = await POST("login", doc);
      const json = await response.json();

      if (response.ok) {
        this.setState({ step: 1 });
      } else if (response.status === 404 && json.error === "phone") {
        this.setState({ error: "אין לנו את הטלפון שלך, רענן ונסה שוב." });
      } else {
        this.setState({
          [json.error]: {
            ...this.state[json.error],
            status: "error",
            helper_text: Errors[json.error][response.status],
          },
          loading: false,
        });
      }
    } catch {
      this.setState({ error: "שגיאה! נסו שוב." });
    } finally {
      this.setState({ loading: false });
    }
  };

  submitSms = async () => {
    this.setState({ loading: true });

    let doc = {
      email: this.state.email.value,
      code: this.state.code.value,
      stay: this.state.stay.value,
    };

    const response = await POST("v1/login/validate", doc);
    const json = await response.json();

    if (response.status === 303) {
      return this.setState({ step: 2, loading: false });
    }
    if (json.token) {
      localStorage.setItem("token", json.token);
      return (window.location.href = "/");
    }
    this.setState({
      [json.error]: {
        ...this.state[json.error],
        status: "error",
        helper_text: Errors[json.error][response.status],
      },
      loading: false,
    });
  };

  changePassword = async () => {

    if (!validatePassword(this.state.new_password.value)) {
      return this.setState({
        new_password: {
          ...this.state.new_password,
          status: "error",
          helper_text: Errors["new_password"][400],
        },
      });
    }

    let doc = {
      email: this.state.email.value,
      old_password: this.state.password.value,
      new_password: this.state.new_password.value,
    };

    const response = await POST("v1/login/first-login", doc);
    const json = await response.json();

    if (response.ok) {
      localStorage.setItem("token", json.token);
      return (window.location.href = "/");
    }
    this.setState({
      [json.error]: {
        ...this.state[json.error],
        status: "error",
        helper_text: Errors[json.error][response.status],
      },
    });
  };

  componentDidUpdate(_, prevState) {
    if (
      prevState.new_password.value !== this.state.new_password.value ||
      prevState.new_password_again.value !== this.state.new_password_again.value
    ) {
      if (
        this.state.new_password.value === this.state.new_password_again.value
      ) {
        this.setState({
          new_password: { ...this.state.new_password, status: "success" },
          new_password_again: {
            ...this.state.new_password_again,
            status: "success",
          },
        });
      } else {
        this.setState({
          new_password: { ...this.state.new_password, status: "" },
          new_password_again: {
            ...this.state.new_password_again,
            status: "error",
            helper_text: "הסיסמאות אינן תואמות",
          },
        });
      }
    }
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Form}>
          <div className={styles.Logo}>
            <img src={Logo} alt={"Logo"} />
          </div>
          <div className={styles.Headline}>
            <h1>התחברות</h1>
          </div>
          <div className={styles.Content}>
            {this.state.loading ? (
              <Loading />
            ) : this.state.error ? (
              <>{this.state.error}</>
            ) : this.state.step === 0 ? (
              <StepOne
                writeData={this.writeData}
                readData={this.readData}
                Submit={this.submitFirstStep}
              />
            ) : this.state.step === 1 ? (
              <StepTwo
                writeData={this.writeData}
                readData={this.readData}
                Submit={this.submitSms}
              />
            ) : this.state.step === 2 ? (
              <StepThree
                writeData={this.writeData}
                readData={this.readData}
                Submit={this.changePassword}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
