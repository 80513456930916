import React from "react";
import styles from "./style.module.scss";
import Input from "../../../../../comps/input";

export default function WatchLactorReview({
    GradeTable,
    name,
    finalReviewHeadline,
    finalReview,
}) {
    return (
        <div className={styles.Container}>
            <h1>{name}</h1>
            {GradeTable.map((item, index) => (
                <div className={styles.Field} key={index}>
                    <Input
                        type={"textarea"}
                        label={item.reasoning ? item.grade : ""}
                        placeholder={item.grade}
                        value={item.reasoning}
                        status={item.reasoning_status}
                        disabled={true}
                        helper_text={item.reasoning_helper_text}
                    />
                    <label>ציון ({item.score || 0})</label>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        value={item.score || 0}
                        disabled={true}
                    />
                </div>
            ))}
            <Input
                placeholder={finalReviewHeadline}
                label={finalReviewHeadline}
                type={"textarea"}
                options={"large"}
                value={finalReview}
                disabled={true}
            />
        </div>
    );
}
