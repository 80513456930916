import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import { GET } from "../../../../API";

import styles from "./style.module.scss";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import { useCookies } from "react-cookie";

export default function GetDate() {

  const GoTo = useNavigate();

  const { category } = useParams();

  const [searchParams] = useSearchParams();
  const date_id = searchParams.get("date_id");

  const [_cookies, setCookie] = useCookies();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [subTypes, setSubTypes] = useState([]);

  const [previewText, setPreviewText] = useState();

  useEffect(() => {
    if (!date_id) return;

    (async function () {
      try {
        const response = await GET(`art-type/sub-types?date_id=${date_id}`);
        const { sub_types, preview_text } = await response.json();

        if (!sub_types) throw Error();
        setSubTypes(sub_types.sort((a, b) => a.order - b.order));

        if(preview_text) setPreviewText(preview_text)
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [date_id]);


  const createSession = async (id) => {
    try {
      const response = await GET(
        `request/create-session?sub_id=${id}&date_id=${date_id}`
      );
      const { form_session } = await response.json();

      setCookie("form_session", form_session, { path: "/", maxAge: 86400 * 3 });
      setCookie("session_type", category, { path: "/", maxAge: 86400 * 3 });
      GoTo(`/${category}/open-dates/form`);
    } catch {
      setError(true);
    }
  };

  if (loading) return <LoadingScreenWithHeader />;
  if (error) return <div>Error</div>;

  return (
    <div className={styles.Container}>
      <div>
        {previewText ? 
        <div className={styles.PreviewDiv}>
          {previewText}
        </div> : null}
      </div>
      <div className={styles.Table}>
        {subTypes.map((subType, index) => (
          <div
            className={styles.Row}
            key={index}
            onClick={() => createSession(subType.id)}
          >
            <h2>{subType.name}</h2>
            <button>
              <BsFillArrowLeftCircleFill />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
