import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./style.module.scss";
import FilmLogo from "../../assets/images/Film-Logo-white.png";
import HelpLogo from "../../assets/images/Help-Logo.png";
import ArtLogo from "../../assets/images/Art-Logo.png";

export default function Header({ links }) {
  const GoTo = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { category } = useParams();

  const Logos = {
    film: FilmLogo,
    art: ArtLogo,
    publish: ArtLogo,
    help: HelpLogo,
  };

  // We may want to reload the page, and not only use useNavigate
  const createUrl = (doc) => {
    if (doc.href) {
      return {
        href: doc.href,
      };
    }
    return {
      onClick: () => {
        setIsOpen(false);
        GoTo(doc.link);
      },
    };
  };

  return (
    <div className={styles.Container}>
      <nav className={`${styles.Links} ${isOpen ? styles.Mobile : ""}`}>
        <ul>
          {links.map((link, index) => (
            <>
              <li key={index}>
                <a {...createUrl(link)}>{link.name}</a>
                {!link.options?.length ? null : (
                  <div className={styles.dropdownList}>
                    <ul>
                      {link.options.map((option, index) => (
                        <li key={index}>
                          <a {...createUrl(option)}>{option.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </>
          ))}
        </ul>
      </nav>
      <div
        className={`${styles.Hamburger} ${isOpen ? styles.MenuClose : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div></div>
      </div>
      <div className={styles.Logo}>
        <img src={Logos[category] || FilmLogo} alt="Logo" />
      </div>
    </div>
  );
}
