import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { RiLockPasswordLine } from "react-icons/ri";
import { GET, POST } from "../../../../API";
import Button from "../../../../comps/button";
import Dialog from "../../../../comps/dialog";
import Input, { Select } from "../../../../comps/input";
import Loading from "../../../../comps/loading";
import Notification from "../../../../comps/Notification";
import { default as Config } from "../../../../config";
import styles from "./style.module.scss";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Error from "../../../../comps/Error";
import ProgressBar from "../../../../comps/progress_bar";

export default function AllUsers() {
  const GoTo = useNavigate();

  const { category } = useParams();

  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState();

  const [Notif, setNotif] = useState();
  const [theDialog, setTheDialog] = useState();

  const [Roles, setRoles] = useState([]);
  const [Users, setUsers] = useState([]);

  const [Total, setTotal] = useState(0);
  const [Search, setSearch] = useState("");
  const [SearchByRole, setSearchByRole] = useState(0);
  const [SearchByActivity, setSearchByActivity] = useState();

  const Activities = [
    { name: "כולם", value: "" },
    { name: "פעיל", value: "true" },
    { name: "לא פעיל", value: "false" },
  ];

  useEffect(() => {
    if (Roles.length) return;
    getRoles();
  }, []);

  useEffect(() => {
    getUsers();
  }, [Search, SearchByRole, SearchByActivity]);

  const getUsers = async (loadMore = false) => {
    setIsLoading(true);
    setIsError(false);

    let amount = 20;
    let link = `users/${category}/all?name=${Search}&role=${
      Roles[SearchByRole]?.id || ""
    }&activity=${SearchByActivity}&page=${
      loadMore ? Users.length / amount : 0
    }&amount=${amount}`;

    try {
      const response = await GET(link);
      const { total, users, error } = await response.json();

      if (error) {
        throw new Error();
      }
      if (loadMore) {
        setUsers([...Users, ...users]);
      } else {
        setUsers(users);
      }
      setTotal(total);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await GET(`get-data/all-roles`);
      const { roles, error } = await response.json();

      if (error) {
        throw new Error();
      }
      setRoles([{ role_name: "כולם", id: "" }, ...roles]);
    } catch {
      setRoles([{ role_name: "שגיאה בטעינה", id: "" }]);
    }
  };

  const manageActive = async (index) => {
    if (Notif) return;

    const userId = Users[index].id;
    let link = `manage-users/${
      Users[index].active ? "deactivate" : "reactivate"
    }?id=${userId}`;

    try {
      const response = await POST(link);

      if (response.status === 200) {
        setUsers((prev) =>
          prev.map((user) => {
            if (user.id === userId) {
              return { ...user, active: !user.active };
            }
            return user;
          })
        );

        sendNotification("הפעולה בוצעה בהצלחה");
      } else throw new Error();
    } catch {
      sendNotification("אירעה שגיאה");
    }
  };

  const HandleResetPass = (index) => {
    setTheDialog({
      text: "לאפס את הסיסמה למשתמש זה?",
      onYes: () => resetPassword(index),
      onNo: () => setTheDialog(),
    });
  };

  const resetPassword = async (index) => {
    setTheDialog();

    try {
      const response = await POST(
        `manage-users/reset-password?id=${Users[index].id}`
      );

      if (response.status === 200) {
        setUsers(
          Users.map((user, i) => {
            if (i === index) {
              return {
                ...user,
                reseting_password: true,
              };
            }
            return user;
          })
        );
        sendNotification("הסיסמה אופסה בהצלחה");
      } else {
        throw new Error();
      }
    } catch {
      sendNotification("אירעה שגיאה");
    }
  };

  const sendNotification = (text) => {
    setNotif(text);
    setTimeout(() => setNotif(""), 3000);
  };

  if (isError) return <Error />;
  return (
    <div className={styles.Container}>
      {Notif && <Notification html={Notif} />}
      {theDialog && <Dialog {...theDialog} />}
      <div className={styles.Search}>
        <Input
          placeholder={"שם משתמש"}
          label={`חיפוש משתמשים (${Total})`}
          icon={<GoSearch />}
          onChange={(e) => setSearch(e.target.value)}
          value={Search}
        />
        <Select
          dropdown={Roles.map(
            (role) => Config[role.role_name] || role.role_name
          )}
          WhenSubmit={(_, i) => setSearchByRole(i)}
          label={"חפש לפי תפקיד"}
        />
        <Select
          dropdown={Activities.map((activity) => activity.name)}
          WhenSubmit={(item) =>
            Activities.forEach((activity) => {
              if (activity.name === item) setSearchByActivity(activity.value);
            })
          }
          label={"חפש לפי פעילות"}
        />

        <Button options={"square hover_dark"} onClick={() => GoTo(`manage`)}>
          <BsPlusLg />
        </Button>
      </div>
      <div className={styles.Cards}>
        {Users.map((user, index) => (
          <div className={styles.Card} key={index}>
            <div
              className={styles.Name}
              onClick={() =>
                GoTo(`manage?id=${user.id}`, {
                  state: JSON.stringify(user),
                })
              }
            >
              <h2>{user.full_name}</h2>
            </div>
            <div
              className={styles.Info}
              onClick={() =>
                GoTo(`manage?id=${user.id}`, {
                  state: JSON.stringify(user),
                })
              }
            >
              <span>{Config[user.role]}</span>
              <span>{user.email}</span>
              <span>{user.mobile}</span>
            </div>
            {!user.submited_reviews && !user.open_reviews ? null : (
              <ProgressBar
                progress={`${
                  (100 * user.submited_reviews) /
                  (user.open_reviews + user.submited_reviews)
                }%`}
              >
                {user.open_reviews + user.submited_reviews} /{" "}
                {user.submited_reviews}
              </ProgressBar>
            )}
            <div className={styles.Btns}>
              <Button
                options={"hover_dark small"}
                onClick={() => manageActive(index)}
              >
                {user.active ? "הפוך ללא פעיל" : "הפוך לפעיל"}
              </Button>
              {!user.reseting_password && (
                <Button
                  onClick={() => HandleResetPass(index)}
                  options={"square hover_dark small"}
                >
                  <RiLockPasswordLine />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      {!isLoading && !Users.length && <>לא נמצאו משתמשים</>}
      {isLoading && <Loading />}
      {Total > Users.length && (
        <button onClick={() => getUsers(true)} className={styles.LoadMore}>
          לטעון עוד...
        </button>
      )}
    </div>
  );
}
