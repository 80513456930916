import React, { useState, useEffect } from "react";
import Button from "../../../../../../comps/button";
import Input from "../../../../../../comps/input";
import styles from "./style.module.scss";
import { FaFileUpload } from "react-icons/fa";
import FilePreview from "./file_preview";

export default function FormStep2({ fields, uploadFile, submitStep }) {
  const [showFilePreview, setshowFilePreview] = useState();
  const [ErrorsCounter, setErrorCounter] = useState();

  useEffect(() => {
    setErrorCounter(
      fields.reduce(
        (prv, crr) => (crr.status === "error" ? (prv += 1) : prv),
        0
      )
    );
  }, [fields]);


  const HandleShowFilePreview = (field, index) => {
    if (typeof field.files[index] === "string") {
      setshowFilePreview({
        link: field.files[index],
        onLoad: () => {},
        close: () => setshowFilePreview(),
      });
    } else {
      setshowFilePreview({
        link: URL.createObjectURL([...field.files][index]),
        onLoad: () => URL.revokeObjectURL(field.files[index]),
        close: () => setshowFilePreview(),
      });
    }
  };

  const renderElement = (field) => {
    field.files = Array(field.max)
      .fill()
      .map((_, index) => field.files[index] || ""); // [file, "", ""] / [""] / [file, file]

    return (
      <>
        {field.files.map((file, index) => (
          <div key={index}>
            <Input
              type="file"
              comment={field.comment}
              placeholder={file.name || file ? "קובץ אחד" : index ? field.placeholder.slice(0, field.placeholder.length-1) : field.placeholder}
              label={!index ? field.label : ""}
              helper_text={!index && field.helper_text}
              status={!index && field.status}
              icon={<FaFileUpload />}
              accept={"application/pdf"}
              options={"large"}
              onChange={(e) => uploadFile(field.name, e.target.files[0], index)}
            />
          </div>
        ))}
        {field.files.length ? (
          <div className={styles.file__names}>
            {[...field.files].map((file, index) =>
              file ? (
                <span
                  onClick={() => HandleShowFilePreview(field, index)}
                  key={index}
                >
                  {field.max > 1 ? `${index + 1}.` : null}{" "}
                  {file.name || `פתח קובץ`}
                </span>
              ) : null
            )}
          </div>
        ) : null}
      </>
    );
    
  };

  return (
    <div className={styles.Container}>
      {showFilePreview && <FilePreview {...showFilePreview} />}
      {fields.map((field, index) => (
        <div key={index}>{renderElement(field)}</div>
      ))}
      <div className={styles.Btn}>
        <Button options={"hover_dark"} onClick={submitStep}>
          אישור
        </Button>
        {ErrorsCounter ? (
          <span className={styles.Error}>
            שימו לב, יש לכם {ErrorsCounter} שגיאות
          </span>
        ) : null}
      </div>
    </div>
  );
}
