import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { GET, DELETE } from '../../../API'; 
import { useParams } from "react-router-dom";
import styles from "./style.module.scss";

// const MainPage = ({ onEdit, onDelete, onCreate }) => {
const MainPage = () => {
    const navigate = useNavigate();
    const { category } = useParams();
    const [forms, setForms] = useState([]);

    const onCreate = () => {
        // For now, just navigate to the form builder page
        // In a real app, you might also need to setup a blank state for the form
        navigate(`${category}/form-builder`);
    };

    useEffect(() => {
      getForms();
    },[])

    const getForms = async () => { 
      const response = await GET(`form/type/${category}`);
      const json = await response.json();
      setForms(json.results);
    }

    const onEdit = async (formId) => {
        try {
            navigate({
              pathname: `/${category}/form-builder/?form_id=${formId}`,
            });
        } catch (error) {
            console.error("Failed to fetch form data for editing:", error);
            alert("Failed to load form for editing.");
        }
    };
    

    const onDelete = async (formId) => {
        if (window.confirm("Are you sure you want to delete this form?")) {
            try {
            const success = await DELETE(`form/${formId}`);
            if (success) {
                alert("Form deleted successfully.");
                // Refresh your forms list here, e.g., by re-fetching them from the backend
            } else {
                alert("Failed to delete the form.");
            }
            } catch (error) {
            console.error("Error deleting form:", error);
            alert("An error occurred while deleting the form.");
            }
        }
    };

  return (
    <div className={styles.formsContainer}>
    <h1>Forms</h1>
    {forms.map(form => (
      <div key={form._id.$oid}>
        <h2>{form.name}</h2>
        <button onClick={() => onEdit(form._id.$oid)}>Edit</button>
        <button onClick={() => onDelete(form._id.$oid)}>Delete</button>
      </div>
    ))}
    <button onClick={onCreate}>Create New Form</button>
  </div>
  );
};


export default MainPage;
