import styles from "./style.module.scss";

export default function Button(props) {
  const cls = props.options
    ?.split(" ")
    .map((option) => styles[option])
    .join(" ");
  return (
    <button className={`${styles.btn} ${cls}`} {...props}>
      {props.children}
    </button>
  );
}
