import styles from "./style.module.scss";
import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GET } from "../../../../API";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import { AiOutlineEdit } from "react-icons/ai";

export default function AllEmails() {
  const [mailTemplates, setMailTemplates] = useState("");
  const [fetchLoading, setLoading] = useState(false);
  const { category } = useParams();
  const GoTo = useNavigate();

  useEffect(() => {
    (async function () {
      setLoading(true)
      try {
        const response = await GET(`email-templates/${category}/all`);
        let {templates} =  await response.json();
        setMailTemplates(templates);

      } catch {
  
      } finally {
        setLoading(false)
       
      }
    })();
  }, []);

  
  return (
  <div className={styles.Container}>
    {fetchLoading ? <LoadingScreenWithHeader /> : (
      <div className={styles.templates}>   
        <h1>ניסוח דוא"ל</h1>
        <ul>
        {!mailTemplates ? null : mailTemplates.map((template, index) => (
            <li
                onClick={() => GoTo(`by_id?id=${template.id}`)}
              ><span>{template.name}</span> <AiOutlineEdit /></li>
            )
          )}</ul>
      </div>
    ) }
  </div>
  );
}
