import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./style.module.scss";
import { GET, POST, PUT, DELETE } from "../../../API";
import Button from "../../../comps/button";
import { AiOutlineCloudDownload } from "react-icons/ai";
import Loading, { LoadingScreenWithHeader } from "../../../comps/loading";
import { FaPlus } from "react-icons/fa";
import Input from "../../../comps/input";
import { GoSearch } from "react-icons/go";
import { formatDate } from "../../../helpers";
import { BsArrowLeft } from "react-icons/bs";

const AMOUNT = 20;

export default function Archive() {
  const { category } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  const getAllDates = async (loadMore = false) => {
    setIsLoading(true);
    try {
      let link;
      if (loadMore) {
        link = `due-dates/${category}/all?amount=${AMOUNT}&page=${Math.ceil(
          dates.length / AMOUNT
        )}&search=${search}`;
      } else {
        link = `due-dates/${category}/all?amount=${AMOUNT}&page=0&search=${search}`;
      }

      console.log("getting data");

      const res = await GET(link);
      const { dates: _dates, total } = await res.json();

      console.log(dates, total);

      setDates((prv) => {
        if (loadMore) {
          return [...prv, ..._dates];
        }
        return _dates;
      });
      setTotal(total);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    getAllDates();
  }, [search]);

  const createArchive = async (idx) => {
    const date = dates[idx];
    toggleProcess(idx);

    try {
      const res = await PUT(`v1/archive/?date_id=${date.id}`);

      if (res.ok) {
        setDates((prv) => {
          const _dates = [...prv];
          _dates[idx].archive_in_process = true;
          return _dates;
        });
      }
    } catch {}
  };

  const deleteArchive = async (idx) => {
    const date = dates[idx];
    toggleProcess(idx);

    try {
      const res = await DELETE(`v1/archive/?date_id=${date.id}`);

      if (res.ok) {
        setDates((prv) => prv.filter((date) => date.id !== dates[idx].id));
        setTotal((prv) => prv - 1);
      }
    } catch {}
  };

  const getArchive = async (idx) => {
    const date = dates[idx];

    try {
      const res = await GET(`v1/archive/?date_id=${date.id}`);

      if (res.ok) {
        const { url } = await res.json();
        window.open(url, "_blank");
      }
    } catch {}
  };

  const toggleProcess = (idx) => {
    setDates((prv) => {
      const _dates = [...prv];
      _dates[idx].archive_in_process = !_dates[idx].archive_in_process;
      return _dates;
    });
  };

  return (
    <div className={styles.Container}>
      <h1>ניהול ארכיון</h1>

      <div className={styles.SearchBar}>
        <Input
          placeholder={"חיפוש תאריכים"}
          icon={<GoSearch />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className={styles.CardsWrraper}>
        {dates.map((date, i) => (
          <div key={i} className={styles.Card}>
            <h2>{date.name}</h2>
            <div className={styles.Dates}>
              <span>{formatDate(date.start_date)}</span>
              <BsArrowLeft />
              <span>{formatDate(date.end_date)}</span>
            </div>
            <div>
              {date.archive_in_process ? (
                <Button disabled>בתהליך</Button>
              ) : date.archive_created ? (
                <>
                  <Button onClick={() => deleteArchive(i)}>
                    מחיקת תאריך וארכיון
                  </Button>
                  <Button options={"square"} onClick={() => getArchive(i)}>
                    <AiOutlineCloudDownload />
                  </Button>
                </>
              ) : (
                <Button onClick={() => createArchive(i)}>יצירת ארכיון</Button>
              )}
            </div>
          </div>
        ))}
      </div>

      {dates.length < total && (
        <div className={styles.LoadMore}>
          {isLoading ? (
            <Loading />
          ) : (
            <button onClick={() => getAllDates(true)}>טען עוד</button>
          )}
        </div>
      )}
    </div>
  );
}
