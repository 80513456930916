import React from "react";
import { useParams } from "react-router-dom";
import RequestsMain from "./requests";
import GetRequestById from "./by_id";

export default function Requests() {
  const { action } = useParams();

  switch (action) {
    case "by_id":
      return <GetRequestById />;
    default:
      return <RequestsMain />;
  }
}
