import styles from "../../style.module.scss";

import Button from "../../../../../comps/button";
import Input from "../../../../../comps/input";
import Checkbox from "../../../../../comps/checkbox";

import { FaUserAlt } from "react-icons/fa";
import { BsFillShieldLockFill } from "react-icons/bs";

import React from "react";

export default function StepOne({ readData, writeData, Submit }) {
  return (
    <>
      <Input
        placeholder={"תיבת מייל"}
        type={"email"}
        icon={<FaUserAlt />}
        onChange={(e) => writeData(e.target.value, "email")}
        value={readData("email").value}
        status={readData("email").status}
        helper_text={readData("email").helper_text}
      />
      <Input
        placeholder={"סיסמה"}
        type={"password"}
        icon={<BsFillShieldLockFill />}
        onChange={(e) => writeData(e.target.value, "password")}
        value={readData("password").value}
        status={readData("password").status}
        helper_text={readData("password").helper_text}
      />
      <Button options={"hover_dark"} onClick={Submit}>
        {"התחברות"}
      </Button>
      <div className={styles.pickers}>
        {/* <div onClick={() => writeData(!readData('tel').value, "tel")}>
          <FaExchangeAlt />
          {readData("tel").value ? "קוד ישלח בסמס" : "קוד ישלח באימייל"}
        </div> */}
        <div>
          <span>קוד:</span>
          <input
            type="radio"
            checked={!readData("tel").value}
            onChange={() => writeData(!readData("tel").value, "tel")}
            id="email"
            name="code-to"
            value="30"
          />
          <label htmlFor="email">לדוא"ל</label>
          <input
            type="radio"
            id="tel"
            name="code-to"
            value="60"
            checked={readData("tel").value}
            onChange={() => writeData(!readData("tel").value, "tel")}
          />
          <label htmlFor="tel">לסמס</label>
        </div>
        <Checkbox
          label={"זכרו אותי"}
          checked={readData("stay").value}
          onChange={(e) => writeData(e.target.checked, "stay")}
        />
      </div>
    </>
  );
}
