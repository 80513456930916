import React, { useEffect, useRef, useState } from "react";
import { AiFillFolderOpen, AiOutlineArrowDown } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { FaLink } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { DELETE, GET, POST } from "../../../../API";
import Button from "../../../../comps/button";
import deepCopy from "../../../../comps/deepcopy";
import Dialog from "../../../../comps/dialog";
import Error from "../../../../comps/Error";
import Input, { Select, setTextAreaHeight } from "../../../../comps/input";
import Loading, { LoadingScreenWithHeader } from "../../../../comps/loading";
import Notification from "../../../../comps/Notification";
import EditLactors from "./edit_lactors";
import WatchLactorReview from "./lactor_review";
import styles from "./style.module.scss";
import { FiMail } from "react-icons/fi";
import GoogleTranslate from "../../../../comps/google_translate";
import FormatRequests from '../requests/index';


const Errors = {
  400: "המידע שהזנת אינו תקין",
  403: "כמות קבצים גדולה מדי",
  404: "שדה זה הינו חובה",
  413: "הקובץ גדול מדי, אפשר עד 8MB",
  415: "סוג קובץ לא נתמך",
};

export default function GetRequestById() {
  const [searchParams, setSearchParams] = useSearchParams();
  const crrDate = searchParams.get("crrDate");
  const page = parseInt(searchParams.get("page") ?? 0);
  const amount = parseInt(searchParams.get("amount") ?? 10);
  const id = searchParams.get("id");
  const [preId, setPrev] = useState("-1");
  const [nextId, setNext] = useState("-1");
  const { category } = useParams();

  const [Headline, setHeadline] = useState("");
  const [SubArt, setSubArt] = useState("");
  const [requestID, setRequestID] = useState("");
  const [lactorsReviews, setlactorsReviews] = useState([]);
  const [Inputs, setInputs] = useState([]);
  const InputsRef = useRef();

  const [finalReviewHeadline, setFinalReviewHeadline] = useState("");

  const [screenError, setScreenError] = useState(false);
  const [screenLoading, setscreenLoading] = useState(false);
  const [editDisabled, setEditDisabled] = useState(true);
  const [BtnLoading, setBtnLoading] = useState(false);

  const [theDialog, setTheDialog] = useState();
  const [Notif, setNotification] = useState("");

  const [ShowPopup, setShowPopup] = useState(false);
  const [CurrStatus, setCurrStatus] = useState();
  const [InitialStatusState, setInitialStatusState] = useState(true);

  const [_currFinalAnswer, setCurrFinalAnswer] = useState();
  const [approvedFunding, setApprovedFunding] = useState();
  const [intialApprovedFunding, setIntialApprovedFunding] = useState();
  const ReqStatusOptions = [
    { name: "לא עבר", id: 2 },
    { name: "עבר לשלב הבא", id: 1 },
    { name: "ממתין להחלטה", id: 3 },
  ];

  const ReqFinalAnswerOptions = [
    { name: "לא אושרה", id: 2 },
    { name: "בקשה אושרה", id: 1 },
    { name: "ממתין להחלטה", id: 3 },
  ];

  useEffect(() => {
    get_request();
  }, [id]);

  useEffect(() => {
    if (CurrStatus && !InitialStatusState) put_request_status();
    setTextAreaHeight();
  }, [CurrStatus]);

  async function put_request_status() {
    if (CurrStatus) {
      try {
        const response = await POST(
          `manage-requests/${category}/pass?id=${id}&passed=${CurrStatus}`
        );
        const json = await response.json();

        if (json.error) {
          sendNotification("אירעה שגיאה");
        } else {
          sendNotification("סטטוס העברת שלב עודכן בהצלחה");
        }
      } catch {
        sendNotification("אירעה שגיאה");
      }
    }
  }

  async function get_request() {
    setscreenLoading(true);
    try {
      const response = await GET(`requests/${category}/by-id?id=${id}`);
      let json = await response.json();

      setHeadline(json.due_date);
      setRequestID(json.request_id);
      setlactorsReviews(json.lactors_reviews);
      setFinalReviewHeadline(json.finall_review_text);
      setSubArt(json.sub_art);
      setInitialStatusState(true);
      setApprovedFunding(json.approved_funding);
      setIntialApprovedFunding(json.approved_funding);
      if (json.passed === true) setCurrStatus(1);
      else if (json.passed === false) {
        setCurrStatus(2);
      } else setCurrStatus(3);

      if (json.final_answer === true) setCurrFinalAnswer(1);
      else if (json.final_answer === false) {
        setCurrFinalAnswer(2);
      } else setCurrFinalAnswer(3);

      // twice for comparing when (if) edited
      let formattedText = formatTextFields(json.text, json.files);

      setInputs(deepCopy(formattedText));
      InputsRef.current = deepCopy(formattedText);
      await getAllRequests(searchParams);
    } catch {
      setScreenError(true);
    } finally {
      setscreenLoading(false);
    }
  }

  function changeId(newId) {
    searchParams.set("id", newId);
    setSearchParams(searchParams);
  }

  async function getAllRequests() {
    try {
      const search = JSON.parse('{"' + decodeURI(searchParams.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      for (var propName in search) {
        if (search[propName] === "" || search[propName] === "0") {
          delete search[propName];
        }
      }
      delete search.id;
      delete search.crrDate;
      delete search.amount;
      delete search.page;
      const response = await POST(
        `requests/${category}/by-date?date_id=${crrDate}`,
        {amount, page, search});
      let { requests } = await response.json();
      console.log(requests);
      const idx = requests.findIndex(value => value.id === id)
      setPrev(idx > 0 ? requests[idx-1].id : -1);
      setNext(idx < requests.length -1 ? requests[idx+1].id : -1);
    } catch (e) {
      console.log(e);
      setScreenError(true);
    } finally {
      setscreenLoading(false);
    }
  }

  function setEmailDialog() {
    setTheDialog({
      text: "האם אתה מעונין בשליחת המייל למשתמש?",
      onNo: () => setTheDialog(),
      onYes: sendReviews,
    });
  }

  async function sendReviews() {
    setTheDialog();
    setBtnLoading(true);
    try {
      const response = await POST(
        `send-emails/${category}/reviews?req_id=${id}`
      );
      const json = await response.json();

      if (json.error) {
        sendNotification("אירעה שגיאה");
      } else {
        sendNotification("הסקירה נשלחה בהצלחה");
      }
    } catch {
      sendNotification("אירעה שגיאה");
    } finally {
      setBtnLoading(false);
    }
  }

  function formatTextFields(fields, files) {
    fields = Object.keys(fields).map((key) => {
      let item = {
        key,
        order: fields[key].order,
        placeholder: fields[key].hebrew_name,
        label: fields[key].hebrew_name,
        type: fields[key].type,
        status: "",
        helper_text: "",
      };
      if (item.type === "select") {
        item.options = fields[key].option;
        item.value = fields[key].value;
        // } else if (item.type === "date") {
        //   let value = fields[key].value.split("-").map((item) => {
        //     if (item.length === 1) return `0${item}`;
        //     return item;
        //   })
        // item.value = value.reverse().join("-") || "";
      } else {
        item.value = fields[key].value || "";
      }
      return item;
    });

    files = Object.keys(files).map((key) => {
      let item = {
        key,
        order: files[key].order,
        type: "file",
        placeholder: files[key].hebrew_name,
        amount: files[key].amount ?? 1,
      };
      return item;
    });

    return [...fields, ...files].sort((a, b) => a.order - b.order);
  }

  const onFieldEdit = (index, value) => {
    let newInputs = [...Inputs];
    newInputs[index].value = value;

    setInputs(newInputs);
  };

  const formatFilePlaceholder = (amount) => {
    switch (amount) {
      case 0:
        return `אין קבצים`;
      case 1:
        return `קובץ אחד בשדה זה`;
      default:
        return `${amount} קבצים בשדה זה`;
    }
  };

  const HandleDelete = () => {
    setTheDialog({
      text: "מחיקת הבקשה תמחק את כל הפרטים והקבצים הקשורים אליה",
      onNo: () => setTheDialog(),
      onYes: DeleteReq,
    });
  };

  const DeleteReq = async () => {
    setTheDialog();

    try {
      const response = await DELETE(`manage-requests/${category}?id=${id}`);

      if (response.status !== 200) {
        throw new Error();
      }
      sendNotification("הבקשה נמחקה בהצלחה");
      setTimeout(() => (window.location.href = `/${category}/requests`), 2000);
    } catch {
      sendNotification("משהו השתבש");
    }
  };

  const displayError = (errors) => {
    setInputs((prv) =>
      prv.map((field) => {
        if (errors[field.key]?.length) {
          field.status = "error";
          field.helper_text = errors[field.key].map(
            (errStatus) => Errors[errStatus]
          );
        }
        return field;
      })
    );
  };

  const HandleEdit = async () => {
    setBtnLoading(true);

    let editedFields = {};
    Inputs.filter(
      (inp, index) => inp.value !== InputsRef.current[index].value
    ).map((inp) => (editedFields[inp.key] = inp.value));

    if (!Object.keys(editedFields).length) return setBtnLoading(false);

    try {
      const response = await POST(
        `manage-requests/${category}/edit?id=${id}`,
        editedFields
      );
      const json = await response.json();

      if (json.error) {
        displayError(json.error);

        sendNotification("חלק מהמידע שהזנת אינו תקין");
      } else {
        sendNotification("עודכן בהצלחה");
      }
    } catch {
      sendNotification("אירעה שגיאה");
    } finally {
      setBtnLoading(false);
    }
  };

  const sendNotification = (text) => {
    setNotification(text);

    setTimeout(() => setNotification(), 3000);
  };

  const OpenPopup = (index) => {
    let newLacts = [...lactorsReviews];
    newLacts[index].show = !newLacts[index].show;
    setlactorsReviews(newLacts);
  };

  const deleteLactor = async (lactor_id) => {
    try {
      const response = await DELETE(
        `manage-reviews/lactors/${category}?lactor_id=${lactor_id}&request_id=${id}`
      );

      if (response.status === 201) {
        sendNotification("הלקטור נמחק בהצלחה");
        setlactorsReviews(
          lactorsReviews.filter((lactor) => lactor.id !== lactor_id)
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        throw new Error();
      }
    } catch (e) {
      sendNotification("אירעה שגיאה בשרת");
    }
  };

  const editLactors = async (lactors) => {
    let doc = {
      lactors,
      request_id: id,
    };
    try {
      const response = await POST(`manage-reviews/lactors/${category}`, doc);

      if (response.status === 201) {
        get_request();
      } else throw new Error();
    } catch {
      sendNotification("שגיאה במהלך העריכה");
    } finally {
      setShowPopup(false);
    }
  };

  const DownLoadReviews = async () => {
    try {
      const response = await GET(
        `get-reviews/${category}/as-file/by-req-id?req_id=${id}`
      );
      const blob = await response.blob();
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "export.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      sendNotification("שגיאה מהשרת");
    }
  };

  const updateFinalAnswer = async (value) => {
    try {
      const response = await POST(
        `manage-requests/${category}/final_answer?id=${id}&final_answer=${value}`
      );
      const json = await response.json();

      if (json.error) {
        sendNotification("אירעה שגיאה");
      } else {
        sendNotification("תשובה סופית עודכנה בהצלחה");
      }
    } catch {
      sendNotification("אירעה שגיאה");
    }
  };

  const updateApprovedFunding = async (e) => {
    if (intialApprovedFunding === e.target.value) return;

    try {
      const response = await POST(
        `manage-requests/${category}/approved_funding?id=${id}&amount=${e.target.value}`
      );
      const json = await response.json();

      if (json.error) {
        sendNotification("אירעה שגיאה");
      } else {
        sendNotification("סכום המימון עודכן בהצלחה");
        setIntialApprovedFunding(e.target.value);
      }
    } catch {
      sendNotification("אירעה שגיאה");
    }
  };

  const buttonsPrevNext=()=>
  {
    let buttons = [];
    if (preId !== -1) {
      buttons.push(
        <div>
          <Button onClick={() => changeId(preId)} >
            הגשה קודמת
          </Button>
        </div>
      );
    }
    if (nextId !== -1) {
      buttons.push(
        <div>
          <Button onClick={() => changeId(nextId) }>
            הגשה הבאה
          </Button>
        </div>
      );
    }
    return buttons;
  };

  if (screenError)
    return (
      <Error
        text={`אירעה שגיאה`}
        description={`
    אירעה שגיאה בטעית פרטי הבקשה, יכול להיות שהבקשה לא קיימת, או שהיא נמחקה.
  `}
      />
    );
  if (screenLoading) return <LoadingScreenWithHeader />;
  return (
    <>
      <GoogleTranslate instant={true} />
      <div className={styles.Container}>
        {ShowPopup && (
          <EditLactors
            setShowPopup={setShowPopup}
            crrLactors={lactorsReviews}
            editLactors={editLactors}
            sendNotification={sendNotification}
          />
        )}
        {Notif && <Notification html={Notif} />}
        {theDialog && <Dialog {...theDialog} />}
        {buttonsPrevNext()}
        <div/>
        <h1>{Headline}</h1>
        <h3>{SubArt}</h3>
        <h4>{requestID}</h4>
        <div className={styles.Lactors}>
          {lactorsReviews.length ? (
            lactorsReviews.map((review, index) => (
              <div key={index}>
                <div className={styles.preview}>
                  <div className={styles.Lactor}>
                    <span>{review.full_name}</span>
                    <span>{review.submitted ? "✔️" : "❌"}</span>
                  </div>
                  <Button
                    options={"square small hover_dark"}
                    onClick={() => OpenPopup(index)}
                  >
                    <AiOutlineArrowDown />
                  </Button>
                </div>
                <div hidden={!review.show}>
                  <WatchLactorReview
                    GradeTable={review.grade_table}
                    name={review.full_name}
                    id={review.review_id}
                    sendNotification={sendNotification}
                    finalReviewHeadline={finalReviewHeadline}
                    finaleReviewe={review.finall_review}
                    deleteLactor={deleteLactor}
                    lactorID={review.id}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className={styles.Lactors}>אין לקטורים לבקשה זו</div>
          )}
        </div>
        <div className={styles.Btns}>
          <Button onClick={() => setShowPopup(true)}>עריכת לקטורים</Button>
          {lactorsReviews.length ? (
            <Button
              options={"square hover_dark"}
              onClick={() => DownLoadReviews()}
            >
              {" "}
              <FiDownload />{" "}
            </Button>
          ) : null}
          <Button onClick={setEmailDialog} options={"square hover_dark"}>
            <FiMail />
          </Button>
        </div>

        <Select
          label={"סטטוס העברת שלב"}
          dropdown={ReqStatusOptions.map((item) => item.name)}
          WhenSubmit={(val) => {
            setInitialStatusState(false);
            setCurrStatus(
              ReqStatusOptions.find((status) => status.name === val).id
            );
          }}
          valueIndex={ReqStatusOptions.findIndex(
            (status) => status.id === CurrStatus
          )}
        />

        {CurrStatus === 1 ? (
          <div>
            <Select
              label={"תשובה סופית"}
              dropdown={ReqFinalAnswerOptions.map((item) => item.name)}
              WhenSubmit={(val) => {
                const value = ReqFinalAnswerOptions.find(
                  (status) => status.name === val
                ).id;
                setCurrFinalAnswer(value);
                updateFinalAnswer(value);
              }}
              valueIndex={ReqFinalAnswerOptions.findIndex(
                (status) => status.id === CurrStatus
              )}
            />
            <Input
              type={"number"}
              label="סכום מימון"
              onChange={(e) => setApprovedFunding(e.target.value)}
              onBlur={updateApprovedFunding}
              value={approvedFunding}
            />
          </div>
        ) : null}

        <div className={styles.Inputs}>
          {Inputs.map((input, index) => {
            if (input.type === "textarea") {
              return (
                <Input
                  type={"textarea"}
                  canTranslate={true}
                  translate={true && editDisabled}
                  key={index}
                  name={input.key}
                  value={input.value}
                  placeholder={input.placeholder}
                  label={input.value ? input.label : ""}
                  onChange={(e) => onFieldEdit(index, e.target.value)}
                  status={input.status}
                  helper_text={input.helper_text}
                  disabled={editDisabled}
                />
              );
            } else if (input.type === "select") {
              return (
                <Select
                  key={index}
                  canTranslate={true}
                  translate={true && editDisabled}
                  name={input.key}
                  dropdown={input.options}
                  valueIndex={input.options.indexOf(input.value)}
                  WhenSubmit={(value, i) => onFieldEdit(index, value)}
                  label={input.label}
                  disabled={editDisabled}
                />
              );
            } else if (input.type === "url") {
              return (
                <Input
                  key={index}
                  name={input.key}
                  value={input.value || ""}
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.value ? input.label : ""}
                  onChange={(e) => onFieldEdit(index, e.target.value)}
                  status={input.status}
                  helper_text={input.helper_text}
                  disabled={editDisabled}
                  icon={
                    <a href={input.value} target={"_blank"} rel={"noreferrer"}>
                      <FaLink />
                    </a>
                  }
                />
              );
            } else if (input.type === "file") {
              return (
                <Input
                  key={index}
                  name={input.key}
                  label={input.placeholder}
                  value={formatFilePlaceholder(input.amount)}
                  onChange={(e) => {}}
                  disabled={editDisabled}
                  icon={
                    <a
                      href={`/${category}/view_file?main_id=${id}&file_key=${input.key}`}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      <AiFillFolderOpen />
                    </a>
                  }
                />
              );
            } else {
              return (
                <Input
                  key={index}
                  canTranslate={true}
                  translate={true && editDisabled}
                  name={input.key}
                  value={input.value || ""}
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.value ? input.label : ""}
                  onChange={(e) => onFieldEdit(index, e.target.value)}
                  status={input.status}
                  helper_text={input.helper_text}
                  disabled={editDisabled}
                />
              );
            }
          })}
        </div>
        {editDisabled ? (
          <Button onClick={() => setEditDisabled(false)}>
            הפוך עריכה לזמינה
          </Button>
        ) : BtnLoading ? (
          <Loading />
        ) : (
          <Button onClick={HandleEdit}>שמור שינויים</Button>
        )}
        <Button onClick={HandleDelete}>מחק בקשה</Button>
      </div>
    </>
  );
}
