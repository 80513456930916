// tility functions for form validation.

// utils/formValidation.js

/**
 * Validates that a field key is unique among all fields.
 * @param {Array} fields - The current list of fields.
 * @param {String} key - The key to validate.
 * @param {String} [excludeId] - Optional ID of a field to exclude from the check (useful for updates).
 * @returns {Boolean} - Whether the key is unique.
 */
export const isKeyUnique = (fields, key, excludeId = null) => {
    return !fields.some(field => field.key === key && field.id !== excludeId);
  };
  
  /**
   * Validates that a field's dependency exists within the fields.
   * @param {Array} fields - The current list of fields.
   * @param {String} dependencyId - The ID of the dependency field to validate.
   * @returns {Boolean} - Whether the dependency is valid.
   */
  export const isDependencyValid = (fields, dependencyId) => {
    if (!dependencyId) return true; // No dependency is always valid.
    return fields.some(field => field.id === dependencyId);
  };
  
  /**
   * Generates error messages for common validation failures.
   * @param {String} type - The type of validation error.
   * @returns {String} - The error message.
   */
  export const getErrorMessage = (type) => {
    const messages = {
      "uniqueKey": "Field key must be unique.",
      "validDependency": "Dependency must be a valid field.",
      // Add more error types and messages as needed.
    };
  
    return messages[type] || "An unknown error occurred.";
  };

  
export const validateFormFields = (formFields) => {
  // Check for any field where 'key' or 'hebrewLabel' is empty or not provided
  const invalidFields = formFields.filter(field => 
    !(field.key?.trim() ?? '') || !(field.hebrewLabel?.trim() ?? '')  || (field.type.trim() === 'click'));

  // Determine if the validation passed based on the presence of invalid fields
  const isValid = invalidFields.length === 0;

  return {
    isValid,
    invalidFields, // Optionally provide details about which fields failed validation
  };
};