// This component renders the form builder UI, including buttons for adding fields, 
// displaying the live representation of the form, and handling field interactions.

// This component will render the form builder UI, including buttons for adding fields and displaying the live form preview. 
// It will map over the form configuration and render FormField components for each field.

import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import TextField from './TextField';
import styles from "../style.module.scss";

const TextBuilder = ({ formFields, addField, removeField, updateFieldProperties }) => {
  return (
    <div className={styles.Container} >
      <h2>שדות טקסט</h2>
      <Droppable droppableId="formFields">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {formFields.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TextField
                      key={field.id} 
                      field={field}
                      formFields={formFields}
                      removeField={removeField}
                      updateFieldProperties={updateFieldProperties}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <button onClick={addField} className='style_btn__APPaZ.style_small__nlrzy'>הוספת שדה</button>
    </div>
  );
};

export default TextBuilder;