import { useEffect, useId, useRef, useState } from "react";
import styles from "./style.module.scss";

const GoogleTranslate = (props) => {
  return null;

  const id = useRef(useId().replaceAll(":", "a")).current;
  const didMount = useRef(false);
  const [hidden, setHidden] = useState(false);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "he",
        autoDisplay: true,
      },
      id
    );
  };

  useEffect(() => {
    if (didMount.current) return;
    didMount.current = true;
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    if (props.instant) {
      setTimeout(() => {
        const googleTranslateElement = document.querySelector(`#${id} select`);

        if (googleTranslateElement) {
          googleTranslateElement.value = "iw";
          googleTranslateElement.dispatchEvent(new Event("change"));
        }
      }, 2000);
    }
  }, []);

  return (
    <div
      onClick={(e) => {
        if (e.target.matches(`.${styles.Container}`)) setHidden((prv) => !prv);
      }}
      className={`${styles.Container} ${hidden ? styles.hidden : ""}`}
      id={id}
    ></div>
  );
};

export default GoogleTranslate;
