import styles from "../../style.module.css";
import Input from "../../../input";
import { GoSearch } from "react-icons/go";

export default function Filter({ Filter, Headline, hideSearchbar }) {
  return (
    <div className={styles.SearchBar}>
      {Headline || ""}
      {hideSearchbar ? null : (
        <Input
          options={"small"}
          placeholder={"חיפוש מתוך מידע קיים"}
          onChange={(e) => Filter(e.target.value)}
          icon={<GoSearch />}
        />
      )}
    </div>
  );
}
