import Button from "../../../../../comps/button";
import Input from "../../../../../comps/input";
import React from "react";
import { AiOutlineReload, AiFillLock } from "react-icons/ai";

export default function StepThree({ readData, writeData, Submit }) {
  return (
    <>
      <Input
        placeholder={"סיסמה חדשה"}
        type={"password"}
        icon={<AiFillLock />}
        value={readData("new_password").value}
        onChange={(e) => writeData(e.target.value, "new_password")}
        status={readData("new_password").status}
        helper_text={readData("new_password").helper_text}
      />
      <Input
        placeholder={"אישור סיסמה"}
        type={"password"}
        icon={<AiOutlineReload />}
        value={readData("new_password_again").value}
        onChange={(e) => writeData(e.target.value, "new_password_again")}
        status={readData("new_password_again").status}
        helper_text={readData("new_password_again").helper_text}
      />
      <Button options={"hover_dark"} onClick={Submit}>
        {"שנה סיסמה"}
      </Button>
    </>
  );
}
