import styles from "./style.module.scss";

export default function Checkbox(props) {
  return (
    <label className={styles.Checkbox}>
      <input type="checkbox" {...props} />
      {props.label}
    </label>
  );
}

export function ThreeWayCheckbox(props) {
  return (
    <label className={styles.ThreeWayCheckbox}>
      <input type="checkbox" {...props} />
      {props.label}
    </label>
  );
}
