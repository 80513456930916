import React, { useState, useEffect }from "react";
import { GET, POST } from "../../../../API";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import Input from "../../../../comps/input";
import Button from "../../../../comps/button";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import Notification from "../../../../comps/Notification";
import { RiMenuUnfoldLine } from "react-icons/ri";
import DetailsPopup from "../../../../comps/DetailsPopup";

export default function EmailById() {
    const [showPopup, setShowPopup] = useState(false);

    const [emailTemplate, setEmailTemplate] = useState();
    const [mailLegend, setMailLegend] = useState();
    const [intialEmailState, setIntialEmailState] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const { category } = useParams();
    const [searchParams] = useSearchParams();
    const [changes, setChanges] = useState(false);
    const [Notif, setNotification] = useState(false);
    const id = searchParams.get("id");

    const GoTo = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                if(!id) {
                    throw new Error();
                }

                const response = await GET(`email-templates/by-id?id=${id}`);

                if (response.ok){
                    const { template, legend } = await response.json();
                    setMailLegend(legend);
                    setEmailTemplate(template);
                    setIntialEmailState(template);
                    setIsLoading(false);
                }else{
                    throw new Error();
                }
            } catch {
                GoTo(`/${category}/auto-mailer`)
            };
        }
        )();
    }, [])
    
    const sendNotification = (text) => {

        setNotification(text);
    
        setTimeout(() => setNotification(false), 3000);
    };

    const handleChange = (e) => {

        if(!changes){
            setChanges(true)
        };

        const { name, value } = e.target;
        setEmailTemplate((prevState) => {
            return {
                ...prevState,
                [name]: value
                }
            }
        );
    };

    const handleSubmit = () => {

        let doc = {};    

        for (let [key, value] of Object.entries(emailTemplate)) {
            if(value !== intialEmailState[key]){
                doc[key] = value;
            };  
        };

        if (!Object.keys(doc).length){
            setChanges(false);
            sendNotification("לא בוצעו שינויים");
            return;
        };

        (async function () {
            setIsLoading(true);
            try {
                const response = await POST(`email-templates/edit?id=${id}`, doc);

                if (response.ok){

                    setChanges(false);
                    setIntialEmailState(emailTemplate);
                    sendNotification("עודכן בהצלחה");

                }else{
                    throw new Error();
                }
            }catch {
                sendNotification("אירעה שגיאה במהלך השמירה");
            };

        setIsLoading(false);

        })();
    };

    if (isLoading) {
        return <LoadingScreenWithHeader />;
    };
    
    return (
    <>
    {showPopup && mailLegend ? <div className={styles.popup}>
            <DetailsPopup title={"מקרא"} close={() => setShowPopup(false)} data={mailLegend}/>
        </div>
        : 
        null    
    }
        
        <div className={styles.Container}>
            {!isLoading ? 
            <>
                <h2>{emailTemplate.name}</h2>
                <Input 
                    options={"large"} 
                    label={"נושא"} 
                    name={"headline"}
                    value={emailTemplate.headline}
                    onChange={handleChange}
                />
                <Input 
                    type={"textarea"} 
                    options={"large"} 
                    label={"תוכן"} 
                    name={"mail_body"}
                    value={emailTemplate.mail_body}
                    onChange={handleChange}
                />
                <div className={styles.btns}><Button
                    onClick={handleSubmit}
                    disabled={!changes}
                >שמירה
                </Button><Button onClick={() => setShowPopup(true)} options="square dark_hover"><RiMenuUnfoldLine /></Button></div>
                
                </>
            : null}
        </div>
    {Notif && <Notification html={Notif} />}
    </>
    );

};