import React, { useEffect, useState } from "react";
import { GoTriangleDown } from "react-icons/go";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GET, POST } from "../../../../API";
import Button from "../../../../comps/button";
import { ThreeWayCheckbox } from "../../../../comps/checkbox";
import deepCopy from "../../../../comps/deepcopy";
import DetailsPopup from "../../../../comps/DetailsPopup";
import Error from "../../../../comps/Error";
import Input, { Select } from "../../../../comps/input";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import Table from "../../../../comps/table";
import styles from "./style.module.scss";
import { Thead, searchInputs } from "./table-conifgs";

export default function RequestsMain() {
  const GoTo = useNavigate();
  const [searchParams] = useSearchParams();
  const { category } = useParams();

  // global vars
  const CHECKBOX_VALUES = {
    "-1": false,
    0: null,
    1: true,
  };

  const [openFilters, setOpenFilters] = useState(true);

  const [screenLoading, setScreenLoading] = useState();
  const [screenError, setScreenError] = useState(false);
  const [dates, setDates] = useState([]);
  const [crrDate, setCrrDate] = useState("");
  const [popupDetails, setPopupDetails] = useState(false);

  // filters
  const [search, setSearch] = useState({
    project_name: searchParams.get("project_name") ?? "",
    form_sender: searchParams.get("form_sender") ?? "",
    sub_art: searchParams.get("sub_art") ?? "",
    director: searchParams.get("director") ?? "",
    producers: searchParams.get("producers") ?? "",
    lactors_reviews: searchParams.get("lactors_reviews") ?? "",
    final_answer: searchParams.get("final_answer") ?? 0,
    passed: searchParams.get("passed") ?? 0,
    applicant: searchParams.get("applicant") ?? "",
    author_name: searchParams.get("author_name") ?? "",
    first_name: searchParams.get("first_name") ?? "",
    last_name: searchParams.get("last_name") ?? "",
    request_id: searchParams.get("request_id") ?? "",
  });

  const [ExpectedLength, setExpectedLength] = useState(1);
  const [Loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [amount, setAmount] = useState(10);

  const [Data, setData] = useState([]);

  const [artTypes, setArtTypes] = useState([]);

  const RowsInPage = 10;
  const PaginationVal = 0;

  useEffect(() => {
    // onPagination(), but not onInit(), get data
    if (!crrDate) return;
    get_table();
  }, [page, amount]);

  useEffect(() => {
    // if dates is not empty and we have no crrDate, create one! (and trigger the useEffect below)
    if (dates.length && !crrDate) {
      setCrrDate(dates[0].id);
    }
  }, [dates]);

  useEffect(() => {
    // if we have date_id and no crrDate, setCrrDate(date_id) and trigger re-render of this useEffect
    // if dates are empty, load them, and eventually they will re-render this useEffect
    // if we have crrDate, get data
    // if we have crrDate and we done have date_id, set it!

    let date_id = searchParams.get("date_id");

    if (crrDate && !artTypes.length) {
      get_artTypes();
    }
    if (!crrDate && date_id) {
      setCrrDate(date_id);
    } else if (!dates.length) {
      get_dates();
    }
    if (crrDate) {
      if (date_id !== crrDate) {
        GoTo(`?date_id=${crrDate}`);
      }
      get_table(true);
    }
  }, [crrDate]);

  useEffect(() => {
    let query = [];
    let FormattedSearch = FormatSearch();
    for (let key in FormattedSearch) {
      query.push(`${key}=${FormattedSearch[key]}`);
    }
    GoTo(`/${category}/requests?date_id=${crrDate}&${query.join("&")}`);
  }, [search]);

  const format_subTypes = (sub_types) => {
    sub_types.forEach((type, index) => {
      type.name = `${type.name}${" "}(${type.serial_number})`;
    });
    return sub_types.sort((a, b) => a.order - b.order);
  };

  const get_artTypes = async () => {
    try {
      const response = await GET(
        `art-type/user-sub-types/by-date?date_id=${crrDate}`
      );
      const { sub_arts } = await response.json();
      const formattedTypes = format_subTypes(sub_arts);

      setArtTypes([{ id: "", name: "" }, ...formattedTypes]);
    } catch {}
  };

  async function get_dates() {
    try {
      setScreenLoading(true);
      const response = await GET(
        `due-dates/${category}/by-user-pro-arts?amount=69420`
      );
      const { dates } = await response.json();

      setDates(dates.map((date) => ({ id: date.id, name: date.name })));
    } catch {
      setScreenError(true);
    } finally {
      setScreenLoading(false);
    }
  }

  const get_table = async (replaceData = false) => {
    let expectedArr = Data.slice(amount * page, amount * page + amount);

    // if data exists in the table, dont load it..
    if (!expectedArr.includes(undefined) && expectedArr.length && !replaceData)
      return;
    try {
      setLoading(true);
      // its not really a post request, more like a GET request with body
      const response = await POST(
        `requests/${category}/by-date-and-user-arts?date_id=${crrDate}`,
        {
          amount,
          page,
          search: FormatSearch(search),
        }
      );
      let { requests, total } = await response.json();
      requests = FormatRequests(deepCopy(requests));

      setExpectedLength(total);

      // designed to make performance better
      // so the array can look like this
      // [{}, {}, {}, empty * 3, {}, {}, {}, empty * 3, {}, {}, {}, empty * 3]

      let newData;
      if (replaceData) {
        newData = Array(total);
        requests.forEach(
          (request, index) => (newData[page * amount + index] = request)
        );
      } else {
        newData = Array(total);
        Data.forEach((request, index) => {
          if (request) {
            newData[index] = request;
          }
        });
        requests.forEach(
          (request, index) => (newData[amount * page + index] = request)
        );
      }

      setData(newData);
    } catch {
      setScreenError(true);
    } finally {
      setLoading(false);
    }
  };

  const FormatRequests = (requests) =>
    requests.map((request, index) => {
      if (request.project_name) {
        request.project_name = {
          value: request.project_name,
          html: (
            <a
              className={styles.Center}
              href={`requests/by_id?id=${request.id}`}
            >
              {request.project_name}
            </a>
          ),
        };
      }
      if (request.form_sender) {
        request.form_sender = {
          value: request.form_sender,
          html: (
            <div
              className={styles.Center}
              center="true"
              onClick={() => {
                setPopupDetails({
                  title: request.form_sender.value,
                  data: request.sender_details,
                });
              }}
            >
              {request.form_sender}
            </div>
          ),
        };
      }
      if (request.first_name) {
        request.first_name = {
          value: request.first_name,
          html: (
            <a
              className={styles.Center}
              href={`requests/by_id?id=${request.id}`}
            >
              {request.first_name}
            </a>
          ),
        };
      }
      if (request.last_name) {
        request.last_name = {
          value: request.last_name,
          html: (
            <div
              className={styles.Center}
              center="true"
              onClick={() => {
                setPopupDetails({
                  title: `${request.last_name.value} ${request.first_name.value}`,
                  data: request.sender_details,
                });
              }}
            >
              {request.last_name}
            </div>
          ),
        };
      }
      if (request.applicant) {
        request.applicant = {
          value: request.applicant,
          html: (
            <div
              className={styles.Center}
              center="true"
              onClick={() => {
                setPopupDetails({
                  title: request.applicant.value,
                  data: request.sender_details,
                });
              }}
            >
              {request.applicant}
            </div>
          ),
        };
      }
      request.lactors_reviews = {
        value: request.lactors_reviews.map((lactor) => lactor.name).join(" "),
        html: (
          <div key={index} className={styles.Lactors}>
            {request.lactors_reviews.map((lactor, lactorIndex) => (
              <div key={lactorIndex} className={styles.Lactor}>
                {lactor.read ? "✔️" : "❌"}
                {lactor.name}
              </div>
            ))}
          </div>
        ),
      };
      request.passed = {
        value: request.passed,
        html: (
          <div center="true">
            {request.passed === true
              ? "כן"
              : request.passed === false
              ? "לא"
              : "ממתין"}
          </div>
        ),
      };
      request.final_answer = {
        value: request.passed,
        html: (
          <div center="true">
            {request.final_answer === true
              ? "כן"
              : request.final_answer === false
              ? "לא"
              : "ממתין"}
          </div>
        ),
      };
      return request;
    });

  const FormatSearch = () => {
    let newSearchQuery = {};
    for (let key in search) {
      if (typeof search[key] !== "string" && search[key]) {
        // in case of the checkboxes, insert to search only if is true or false (not null)
        newSearchQuery[key] = CHECKBOX_VALUES[search[key]];
      } else if (search[key]) {
        newSearchQuery[key] = search[key];
      }
    }
    return newSearchQuery;
  };

  const HandleSetSearch = (queryObj) => setSearch({ ...search, ...queryObj });
  const HandleCheckbox = (key, val) =>
    HandleSetSearch({ [key]: Number(val) === -1 ? 1 : +val - 1 });

  if (screenLoading) return <LoadingScreenWithHeader />;

  if (screenError) return <Error />;

  return (
    <div className={styles.Container}>
      {popupDetails ? (
        <DetailsPopup
          close={() => setPopupDetails(false)}
          title={popupDetails.title}
          data={popupDetails.data}
        />
      ) : null}
      <div
        className={`${styles.Headline} ${openFilters ? styles.open : ""}`}
        onClick={() => setOpenFilters(!openFilters)}
      >
        <h2>חיתוכים</h2>
        <GoTriangleDown />
      </div>
      <div
        className={`${styles.Filters} ${
          openFilters ? "" : styles.hiddenFilters
        }`}
      >
        <div className={openFilters ? "" : styles.hiddenFilters}>
          {searchInputs[category].map((item, index) =>
            item.type === "text" || item.type === "number" ? (
              <Input
                key={index}
                value={search[item.name]}
                onChange={(e) =>
                  HandleSetSearch({
                    [item.name]: e.target.value,
                  })
                }
                options={"small"}
                placeholder={item.placeholder}
                type={item.type}
              />
            ) : item.type === "select" ? (
              <Select
                key={index}
                label={item.placeholder}
                WhenSubmit={(val) =>
                  HandleSetSearch({
                    sub_art: artTypes.find((type) => type.name === val).id,
                  })
                }
                options={"small"}
                dropdown={[...artTypes.map((art) => art.name)]}
              />
            ) : null
          )}
        </div>
        <div>
          <ThreeWayCheckbox
            label={"תשובה סופית"}
            status={search.final_answer}
            onChange={(e) =>
              HandleCheckbox("final_answer", e.target.getAttribute("status"))
            }
          />
          <ThreeWayCheckbox
            label={"עבר שלב"}
            status={search.passed}
            onChange={(e) =>
              HandleCheckbox("passed", e.target.getAttribute("status"))
            }
          />
        </div>
        <div className={styles.Btns}>
          <Button options={"small hover_dark"} onClick={() => get_table(true)}>
            סינון טבלה
          </Button>
        </div>
      </div>
      <Table
        hideSearchbar={true}
        Headline={
          <div className={styles.TableHeadline}>
            <h2>הגשות ({ExpectedLength || 0})</h2>

            <select
              onChange={(e) => setCrrDate(e.target.value)}
              value={crrDate}
            >
              {dates.map((date, index) => (
                <option key={index} value={date.id}>
                  {date.name}
                </option>
              ))}
            </select>
          </div>
        }
        Data={Data}
        Thead={Thead[category]}
        RowsInPage={RowsInPage}
        PaginationVal={PaginationVal}
        Loading={Loading}
        Lazy={{
          ExpectedLength,
          onPagination: (index, RowsInPage) => {
            setPage(index - 1);
            setAmount(RowsInPage);
          },
          onFilter: (val) => {},
        }}
      />
    </div>
  );
}
