import { useEffect, useState } from "react";
import { LoadingScreenWithHeader } from "./comps/loading";
import Flow from "./flow";
import * as Navigator from "./navigation";
import "./styles/style.scss";
import "./styles/vars.scss";
import "./styles/app.css";

export default function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    (async function () {
      setUser(await Flow());
    })();
  }, []);

  if (!user) {
    return <LoadingScreenWithHeader />;
  }
  if (user.role === "visitor") {
    return <Navigator.Visitor />;
  } else if (user.role === "lactor") {
    return <Navigator.Lactor />;
  } else if (user.role === "admin") {
    return <Navigator.Admin />;
  }else if (user.role === "art_manager") {
    return <Navigator.ArtManager />;
  };

  return <>Who are you?</>;
}
