import React, { useEffect, useState } from "react";
import { GET } from "../../../../API";
import styles from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import Error from "../../../../comps/Error";

export default function LactorOpenDates() {
  const { category } = useParams();
  const GoTo = useNavigate();

  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState();
  const [Dates, setDates] = useState([]);

  useEffect(() => {
    get_dates();
  }, []);

  const get_dates = async () => {
    try {
      setIsLoading(true);

      const response = await GET(`due-dates/${category}/my`);
      const { dates, error } = await response.json();

      if(error) {
        throw new Error()
      }
      setDates(dates);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(date) {
    date = new Date(date);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  if (isLoading) return <LoadingScreenWithHeader />;
  if(isError) return <Error />
  return (
    <div className={styles.Container}>
      {!isLoading && !Dates.length && <div>אין תאריכים רשומים אליך</div>}
      {Dates.map((date, index) => (
        <div
          onClick={() => GoTo(`/${category}/requests/reviews?id=${date.id}`)}
          className={`${styles.Card} `}
          key={index}
        >
          {/*styles.Active*/}
          <h2>{date.name}</h2>
          <div>
            <span>{formatDate(date.start_date)}</span>
            <span>{formatDate(date.end_date)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
