import React, { useState, useEffect } from "react";
import { GET } from "../../../../API";
import { useParams } from "react-router-dom";
import styles from "./style.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "../../../../comps/button";
import Checkbox from "../../../../comps/checkbox";
import { MdRadioButtonChecked } from "react-icons/md";
import { ImRadioUnchecked } from "react-icons/im";
import Loading from "../../../../comps/loading";

export default function ExcelPopup(props) {
  const { close, Export } = props;

  const { category } = useParams();
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [isToClear, setIsToClear] = useState(true);

  useEffect(() => {
    if (!category) return;
    get_columns();
  }, [category]);

  const get_columns = async () => {
    try {
      setIsLoading(true);
      const response = await GET(`excel/${category}/columns`);
      setColumns(formatColumns(await response.json()));
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const formatColumns = (columns) => {
    return columns.map((item) => ({
      key: item.key,
      name: item.title,
      chosen: true,
    }));
  };

  const HandleChange = (val, index) => {
    let newCL = [...columns];
    newCL[index].chosen = val;
    setColumns(newCL);
  };

  const HandleSubmit = async () => {
    let doc = [];
    columns.forEach((column) => {
      if (!column.chosen) {
        doc.push(column.key);
      }
    });
    Export(doc);
  };

  const pickAll = () => {
    setColumns(columns.map((column) => ({ ...column, chosen: true })));
    setIsToClear(!isToClear);
  };
  const unpickAll = () => {
    setColumns(columns.map((column) => ({ ...column, chosen: false })));
    setIsToClear(!isToClear);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        <div className={styles.Icon} onClick={close}>
          <AiFillCloseCircle />
        </div>
        <h2>בחירת עמודות</h2>
        <div className={styles.options}>
          {isToClear ? (
            <Button options={"small hover_dark"} onClick={unpickAll}>
              <ImRadioUnchecked /> נקה
            </Button>
          ) : (
            <Button options={"small hover_dark"} onClick={pickAll}>
              <MdRadioButtonChecked /> בחר הכל
            </Button>
          )}
        </div>
        <div className={styles.columns}>
          {isLoading ? (
            <Loading />
          ) : (
            columns.map((column, index) => (
              <div key={index}>
                <Checkbox
                  label={column.name}
                  onChange={(e) => HandleChange(e.target.checked, index)}
                  checked={column.chosen}
                />
              </div>
            ))
          )}
        </div>
        <Button options={"hover_dark"} onClick={HandleSubmit}>
          הורדת מסמך
        </Button>
      </div>
    </div>
  );
}
