import React, { useLayoutEffect, useState } from "react";
import { GET } from "../../../../../API";
import styles from "./style.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { GoArrowDown } from "react-icons/go";
import Input from "../../../../../comps/input";
import Button from "../../../../../comps/button";
import Loading from "../../../../../comps/loading";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

// const MAX_LACTORS = 3;

export default function EditLactors(props) {
  const [searchParams] = useSearchParams();
  const { category } = useParams();
  const req_id = searchParams.get("id");

  const [isLoading, setIsLoading] = useState(false);
  const [AllLactors, setAllLactors] = useState([]);

  const [ActiveLactors, setActiveLactors] = useState(props.crrLactors);

  useLayoutEffect(() => {
    getLactors();
  }, []);

  const getLactors = async (search = "") => {
    setIsLoading(true);
    try {
      const response = await GET(
        `users/${category}/all-lactors/by-request?req_id=${req_id}&amount=5&search=${search}`
      );
      const { lactors } = await response.json();
      setAllLactors(lactors);
    } catch {
      alert("No Lactors Found");
    } finally {
      setIsLoading(false);
    }
  };

  const removeChosen = (lactor) => {
    setActiveLactors(ActiveLactors.filter((l) => l.id !== lactor.id));
    setAllLactors([...AllLactors, lactor])
  }

  const chooseLactor = (id) => {
    let checkIfIdExist = ActiveLactors.find((l) => l.id === id);

    if (checkIfIdExist) return;

    setActiveLactors([...ActiveLactors, AllLactors.find((l) => l.id === id)]);
    setAllLactors(AllLactors.filter((l) => l.id !== id));
  };

  const HandleEdit = () => {
    let lactors = ActiveLactors.map((l) => l.id);
    props.editLactors(lactors);
  };

  return (
    <div className={styles.Container} onLoad={() => getLactors("")}>
      <div className={styles.Card}>
        <div className={styles.Close} onClick={() => props.setShowPopup(false)}>
          <AiFillCloseCircle />
        </div>
        <h2>עריכת לקטורים</h2>

        <div className={styles.CrrLactors}>
          {ActiveLactors.length ? (
            ActiveLactors.map((lactor, index) => (
              <div
                className={styles.Lactor}
                key={index}
                onClick={() => removeChosen(lactor)}
              >
                <span>{lactor.full_name}</span>
                {typeof lactor.submitted === "boolean" ? (
                  <span>{lactor.submitted ? "✔️" : "❌"}</span>
                ) : null}
              </div>
            ))
          ) : (
            <div>לא נבחרו לקטורים</div>
          )}
        </div>

   
          <div className={styles.AllLactors_Container}>
            <Input
              placeholder={"חיפוש לקטורים"}
              icon={<BiSearch />}
              onChange={(e) => getLactors(e.target.value)}
            />

            <div className={styles.AllLactors}>
              {isLoading ? (
                <Loading />
              ) : AllLactors.length ? (
                AllLactors.map((lactor, index) => (
                  <div
                    className={styles.Lactor}
                    key={index}
                    onClick={() => chooseLactor(lactor.id)}
                  >
                    <span>{lactor.full_name}</span>
                    <i>
                      <GoArrowDown />
                    </i>
                  </div>
                ))
              ) : (
                <div>אין נתונים</div>
              )}
            </div>
          </div> 
        

        <Button options={"hover_dark"} onClick={HandleEdit}>
          שמירת שינויים
        </Button>
      </div>
    </div>
  );
}
