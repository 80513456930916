import React, { Component } from "react";
import Content from "./parts/Content";
import Filter from "./parts/Filter";
import TableFooter from "./parts/TableFooter";
import styles from "./style.module.css";
import deepCopy from "../deepcopy";

export default class Table extends Component {
  state = {
    Data: this.props.Data,
    DataToRender: this.props.Data,
    Thead: this.props.Thead,
    RowsInPage: this.props.RowsInPage || 10,
    PaginationVal: this.props.PaginationVal || 1,
  };

  componentDidMount() {
    this.sliceData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.Data !== this.props.Data) {
      this.setState({ Data: this.props.Data });
    }
    if (prevProps.RowsInPage !== this.props.RowsInPage) {
      this.setState({ RowsInPage: this.props.RowsInPage });
    }
    if (prevProps.PaginationVal !== this.props.PaginationVal) {
      this.setState({ PaginationVal: this.props.PaginationVal });
    }
    if (
      this.state.Data !== prevState.Data ||
      this.state.PaginationVal !== prevState.PaginationVal ||
      this.state.RowsInPage !== prevState.RowsInPage
    ) {
      this.sliceData();
    }
    if (
      this.state.PaginationVal !== prevState.PaginationVal ||
      this.state.RowsInPage !== prevState.RowsInPage
    ) {
      // its here and not inside the setPaginationVal() because there it can cause an infinite loop
      if (this.props.Lazy) {
        try {
          this.props.Lazy.onPagination(
            this.state.PaginationVal,
            +this.state.RowsInPage
          );
        } catch {
          console.error(
            "When table is set to lazy loading, it must recieve onPagination function. or maybe the onPagination is not a function."
          );
        }
      }
    }
  }

  sliceData = () => {
    const { Data, RowsInPage, PaginationVal } = this.state;
    this.setState({
      DataToRender: Data.slice(
        PaginationVal * RowsInPage - RowsInPage,
        PaginationVal * RowsInPage
      ),
    });
  };

  setPaginationVal = (PaginationVal) => this.setState({ PaginationVal });

  setRowsInPage = (RowsInPage) => this.setState({ RowsInPage });

  filter = (value) => {
    if (this.props.Lazy) {
      try {
        this.props.Lazy.onFilter(value);
      } catch {
        console.error(
          "When table is set to lazy loading, it must recieve onFilter function. or maybe the onFilter is not a function."
        );
      }
    }

    const Includes = (cellVal) =>
      cellVal.toString().toLowerCase().includes(value.toString().toLowerCase());

    let Data = this.props.Data.filter((item) =>
      Object.keys(item).some((key) => {
        if (typeof item[key] === "undefined") return false;
        if (item[key].filter) {
          return Includes(item[key].filter);
        }
        if (item[key].value) {
          return Includes(item[key].value);
        }
        return Includes(item[key]);
      })
    );

    this.setState({ Data });
  };

  sort = (key) => {
    function Comparing(a, b) {
      if (typeof a[key] === "undefined") {
        a = "";
      } else a = a[key].sort ?? a[key].value ?? a[key];

      if (typeof b[key] === "undefined") {
        b = "";
      } else b = b[key].sort ?? b[key].value ?? b[key];

      let comparison;
      if (Number(a) && Number(b)) {
        comparison = [a, b].sort((a, b) => a - b);
      } else {
        comparison = [a, b].sort();
      }

      if (a === b) return 0;
      if (comparison[0] === a) return 1;
      return -1;
    }

    let DataToRender = deepCopy(this.state.DataToRender).sort(Comparing);

    if (
      JSON.stringify(DataToRender) === JSON.stringify(this.state.DataToRender)
    )
      DataToRender.reverse();
    this.setState({ DataToRender });
  };
  render() {
    return (
      <div className={styles.Table}>
        <Filter
          Filter={this.filter}
          Headline={this.props.Headline}
          hideSearchbar={this.props.hideSearchbar}
        />
        <Content
          Data={this.state.DataToRender}
          Thead={this.state.Thead}
          Sort={this.sort}
          loading={this.props.Loading}
        />
        <TableFooter
          ExpectedLength={
            this.props.Lazy?.ExpectedLength || this.state.Data?.length || 0
          }
          setPaginationVal={this.setPaginationVal}
          setRowsInPage={this.setRowsInPage}
          RowsInPage={this.state.RowsInPage}
          PaginationVal={this.state.PaginationVal}
          DataToRender={this.state.DataToRender}
        />
      </div>
    );
  }
}
