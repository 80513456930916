import React, { useState } from "react";
import styles from "./style.module.scss";
import Input from "../../../../../comps/input";
import Button from "../../../../../comps/button";
import { POST } from "../../../../../API";
import { useParams } from "react-router-dom";

const Errors = {
  400: "מה אתה מנסה לעשות?",
  401: "שדה זה הינו חובה",
  404: "הפעולה שנדרשה לא נמצאה",
  410: "ההגשה הזו אינה בתוקף",
};

export default function WatchLactorReview({
  GradeTable,
  name,
  id,
  sendNotification,
  finalReviewHeadline,
  finaleReviewe,
  deleteLactor,
  lactorID,
}) {
  const [GT, setGT] = useState([...GradeTable]);
  const [FinalReview, setFinalReview] = useState({ text: finaleReviewe || "" });
  const { category } = useParams();


  const sumitEdit = async (done = false) => {
    let doc = {
      grade_table: GT,
      review_id: id,
      done,
      finall_review: FinalReview.text,
    };

    try {
      const response = await POST(`write-review/${category}`, doc);

      if (response.status === 201) {
        if (done) {
          return window.location.reload();
        }
        return sendNotification("נשמר בהצלחה");
      }

      let { error } = await response.json();

      if (error === "finall_review") {
        setFinalReview({
          text: FinalReview.text,
          status: "error",
          helper_text: Errors[response.status],
        });
      }

      sendNotification("שגיאה באחד מהשדות");

      error = error.split(":");
      let newGT = [...GradeTable];

      newGT[error[1]][error[0] + "_status"] = "error";
      if (error[0] === "reasoning") {
        newGT[error[1]][error[0] + "_helper_text"] = Errors[response.status];
      }
      setGT(newGT);
    } catch {
      sendNotification("שגיאה");
    }
  };

  const editField = (index, key, value) => {
    let newGT = [...GT];
    newGT[index][key] = value;

    newGT[index][key + "_status"] = "";
    newGT[index][key + "_helper_text"] = "";

    setGT(newGT);
  };

  return (
    <div className={styles.Container}>
      <h1>{name}</h1>
      {GT.map((item, index) => (
        <div className={styles.Field} key={index}>
          <Input
            type={"textarea"}
            label={item.reasoning ? item.grade : ""}
            placeholder={item.grade}
            value={item.reasoning}
            onChange={(e) => editField(index, "reasoning", e.target.value)}
            status={item.reasoning_status}
            helper_text={item.reasoning_helper_text}
          />
          <label>ציון ({item.score || 0})</label>
          <input
            type="range"
            min="0"
            max="10"
            value={item.score || 0}
            onChange={(e) => editField(index, "score", e.target.value)}
          />
          {item.score_status && <small>דירוג חייב להיות מעל 0</small>}
        </div>
      ))}
      <Input
        placeholder={finalReviewHeadline}
        label={FinalReview.text ? finalReviewHeadline : ""}
        type={"textarea"}
        options={"large"}
        value={FinalReview.text}
        onChange={(e) => setFinalReview({ text: e.target.value })}
        status={FinalReview.status}
        helper_text={FinalReview.helper_text}
      />
      <div className={styles.Btn}>
        <Button options={"hover_dark"} onClick={() => sumitEdit(false)}>
          שמור שינויים
        </Button>
        <Button options={"hover_dark"} onClick={() => sumitEdit(true)}>
          אישור ושמירה
        </Button>
        <Button options={"hover_dark"} onClick={() => deleteLactor(lactorID)}>
          הסרת לקטור
        </Button>
      </div>
    </div>
  );
}
