import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET, POST } from "../../../API";
import Input from "../../../comps/input";
import Notification from "../../../comps/Notification";
import { LoadingScreenWithHeader } from "../../../comps/loading";
import Button from "../../../comps/button"
import styles from "./style.module.scss";

export default function PandaDoc() {

    const { category } = useParams();

    const [requests, setRequests] = useState([]);

    const [inputValue, setInputValue] = useState("");

    const [Notif, setNotification] = useState();

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        (async () => {
            try{
                const response = await GET(`/pandadoc-suck/${category}/all`);
            
                const json = await response.json();
                
                if(!response.ok){
                    throw new Error();
                }

                setRequests(json.requests);

            }catch(err){

                sendNotification("התרחשה שגיאה");
            };
        
        setLoading(false);

        })();
    }, []);

    const sendNotification = (text) => {
        setNotification(text);
    
        setTimeout(() => setNotification(), 3000);
      };

    const handleSubmit = async (e) => {
        setLoading(true);
        try{
            const response = await POST(`/pandadoc-suck/${category}?req_id=${inputValue}`);
            if(response.ok){

                sendNotification("הבקשה העוברה שלב בהצלחה");

            }else if(response.status == 425){

                sendNotification("קובץ עדין לא נחתם");

            }else{
                throw new Error();
            }
        }catch(err){
            sendNotification("התרחשה שגיאה");
        };
        setLoading(false);
    };

    if(loading){
        return <LoadingScreenWithHeader />
    };

    return (
        <div className={styles.Container} >
            {Notif && <Notification html={Notif} />}
            <div>
                {
                    requests ? requests.map(request => (
                        
                        <div>
                            <h3>{request.project_name}</h3>
                            <p>mongo id {request.id}</p>
                            <p>{request.email}</p>
                            <p>{request.phone}</p>
                            <p>{request.form_sender}</p>
                            <p>id {request.applicant_id}</p>
                            <p>panda session {request.panda_session}</p>
                            <p>panda doc id {request.panda_doc_id}</p>
                            
                        </div>
                        
                    )
                    ) : null
                }
            </div>
            <div>
            <Input
                placeholder={"העברת שלב לפי מונגו id"}
                options={"large"}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            <Button
                onClick={handleSubmit}
                >
                העבר שלב
            </Button>
            </div>
            <p>קישור</p>
            <p>https://app.pandadoc.com/s/{"<yourSessionId>"}</p>
        </div>
    )
    


}